import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as CheckmarkIcon } from '../../assets/svg/Checkmark.svg';
import { ReactComponent as InformationIcon } from '../../assets/svg/Information.svg';
import { ReactComponent as WarningIcon } from '../../assets/svg/Warning.svg';
import { ReactComponent as ExclamationIcon } from '../../assets/svg/Exclamation.svg';

const iconVariants = {
    success: <SvgIcon component={CheckmarkIcon} viewBox="0 0 20 20" sx={{ mr: 1 }} />,
    info: <SvgIcon component={InformationIcon} viewBox="0 0 20 20" sx={{ mr: 1 }} />,
    warning: <SvgIcon component={WarningIcon} viewBox="0 0 20 20" sx={{ mr: 1 }} />,
    error: <SvgIcon component={ExclamationIcon} viewBox="0 0 20 20" sx={{ mr: 1 }} />,
};

export default iconVariants;
