import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { RoomImageType } from '../../utils/rooms';
import { useGetRoomsQuery } from '../../redux/rooms/rooms.api';
import useSize from '../../hooks/useSize';
import LinearProgress from '../../components/loaders/LinearProgress';

import RoomImageList from './components/RoomImageList';

const RoomTitle = ({ room, parent }) => {
    const lineHeight = parent ? undefined : '48px';
    const images = useMemo(
        () => room.images.filter((image) => image.type === RoomImageType.RoomIcon),
        [room]
    );

    return (
        <Box sx={{ px: { xs: 1, xl: 3 }, minHeight: 48 }}>
            <Grid container spacing={1}>
                <Grid item>
                    <RoomImageList
                        roomId={room.roomId}
                        images={images}
                        width={60}
                        height={60}
                        type={RoomImageType.RoomIcon}
                        placeholder=""
                    />
                </Grid>
                <Grid item>
                    {parent && (
                        <Typography
                            variant="subtitle1"
                            component="div"
                            sx={{
                                color: 'var(--color-text-muted)',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {parent.name} >
                        </Typography>
                    )}
                    <Typography gutterBottom variant="h3" component="div" sx={{ lineHeight }}>
                        {room.name}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

const RoomItem = ({ room, parent }) => {
    const { boxRef, width } = useSize();
    const compartments = useMemo(() => room.compartments || [], [room]);
    const images = useMemo(
        () => room.images.filter((image) => image.type === RoomImageType.RoomImage),
        [room]
    );

    return (
        <>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card>
                    <CardContent sx={{ px: 0, '&:last-child': { pb: 0 } }}>
                        <RoomTitle room={room} parent={parent} />
                        <Divider sx={{ mt: 3 }} />
                        <Box ref={boxRef} sx={{ minHeight: 216 }}>
                            {width && (
                                <RoomImageList roomId={room.roomId} images={images} width={width} />
                            )}
                        </Box>
                    </CardContent>
                </Card>
            </Grid>
            {compartments.map((compartment) => (
                <RoomItem key={compartment.roomId} room={compartment} parent={room} />
            ))}
        </>
    );
};

const Options = () => {
    const { data: rooms = [], isLoading } = useGetRoomsQuery();

    return (
        <Box sx={{ py: 5, px: 10 }}>
            {isLoading && <LinearProgress />}
            <Typography component="h1" variant="h3">
                Options
            </Typography>
            <Typography sx={{ mt: 3 }}>
                Upload default photos to display under the colour selection process.
            </Typography>
            <Grid container spacing={3} sx={{ mt: 1 }}>
                {rooms.map((room) => (
                    <RoomItem key={room.roomId} room={room} />
                ))}
            </Grid>
        </Box>
    );
};

export default Options;
