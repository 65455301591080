import { useSnackbar } from 'notistack';
import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as CrossIcon } from '../../assets/svg/Cross.svg';

const SnackbarCloseButton = ({ snackbarKey }) => {
    const { closeSnackbar } = useSnackbar();
    return (
        <IconButton onClick={() => closeSnackbar(snackbarKey)}>
            <SvgIcon component={CrossIcon} viewBox="0 0 20 21" sx={{ width: 10, height: 10 }} />
        </IconButton>
    );
};

export default SnackbarCloseButton;
