import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const OutlinedPrimaryButton = styled(Button)(() => ({
    borderRadius: 100,
    textStyle: 'uppercase',
    color: 'var(--company-text-color) !important',
}));

export default OutlinedPrimaryButton;
