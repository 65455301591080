import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Popover from '@mui/material/Popover';
import SvgIcon from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import useDialog from '../../../hooks/useDialog';
import {
    useUpdateCompanyMutation,
    useDeleteCompanyMutation,
} from '../../../redux/companies/companies.api';

import {
    useSendInviteMutation,
    useDeleteInviteMutation,
} from '../../../redux/companies/companies.api';

import ConfirmActionModal from '../../../components/ConfirmActionModal/ConfirmActionModal';
import PopoverButton from '../../../components/PopoverButton/PopoverButton';

import { ReactComponent as EditIcon } from '../../../assets/svg/editClient.svg';
import { ReactComponent as CircleCheckIcon } from '../../../assets/svg/circleCheck.svg';
import { ReactComponent as CircleUncheckIcon } from '../../../assets/svg/cirecleUncheck.svg';
import { ReactComponent as SendIcon } from '../../../assets/svg/send.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/svg/delete.svg';

const ClientStatus = {
    Draft: 'DRAFT',
    Pending: 'PENDING',
    Active: 'ACTIVE',
    Inactive: 'INACTIVE',
    Suspended: 'SUSPENDED',
    Blocked: 'BLOCKED',
};

const PopoverMenu = ({
    client,
    onEdit,
    onSuspend,
    onUnsuspend,
    onBlock,
    onSendInvite,
    onResendInvite,
    onCancelInvite,
    onDelete,
}) => {
    const menu = [
        <StyledMenuItem onClick={onEdit} key="edit">
            <StyledListItemIcon>
                <StyledSvgIcon
                    component={EditIcon}
                    viewBox="0 0 22 22"
                    sx={{ width: 14, height: 14, mr: 1 }}
                />
            </StyledListItemIcon>
            <ListItemText>Edit Client</ListItemText>
        </StyledMenuItem>,
    ];

    switch (client.status) {
        case ClientStatus.Draft: {
            menu.push(
                <StyledMenuItem onClick={onSendInvite} key="send">
                    <StyledListItemIcon>
                        <SvgIcon
                            component={SendIcon}
                            viewBox="0 0 14 13"
                            sx={{ width: 14, height: 14, mr: 1 }}
                        />
                    </StyledListItemIcon>
                    <ListItemText>Send Invite</ListItemText>
                </StyledMenuItem>,
                <StyledMenuItem onClick={onDelete} key="delete">
                    <StyledListItemIcon>
                        <SvgIcon
                            component={DeleteIcon}
                            viewBox="0 0 12 13"
                            sx={{ width: 14, height: 14, mr: 1 }}
                        />
                    </StyledListItemIcon>
                    <ListItemText>Delete Account</ListItemText>
                </StyledMenuItem>
            );

            break;
        }

        case ClientStatus.Pending: {
            menu.push(
                <StyledMenuItem onClick={onResendInvite} key="send">
                    <StyledListItemIcon>
                        <SvgIcon
                            component={SendIcon}
                            viewBox="0 0 14 13"
                            sx={{ width: 14, height: 14, mr: 1 }}
                        />
                    </StyledListItemIcon>
                    <ListItemText>Resend Invite</ListItemText>
                </StyledMenuItem>,
                <StyledMenuItem onClick={onCancelInvite} key="cancel">
                    <StyledListItemIcon>
                        <SvgIcon
                            component={DeleteIcon}
                            viewBox="0 0 12 13"
                            sx={{ width: 14, height: 14, mr: 1 }}
                        />
                    </StyledListItemIcon>
                    <ListItemText>Cancel Invite</ListItemText>
                </StyledMenuItem>,
                <StyledMenuItem onClick={onDelete} key="delete">
                    <StyledListItemIcon>
                        <SvgIcon
                            component={DeleteIcon}
                            viewBox="0 0 12 13"
                            sx={{ width: 14, height: 14, mr: 1 }}
                        />
                    </StyledListItemIcon>
                    <ListItemText>Delete Account</ListItemText>
                </StyledMenuItem>
            );

            break;
        }

        case ClientStatus.Suspended: {
            menu.push(
                <StyledMenuItem onClick={onUnsuspend} key="unsuspend">
                    <StyledListItemIcon>
                        <SvgIcon
                            component={CircleCheckIcon}
                            viewBox="0 0 20 21"
                            sx={{ width: 14, height: 14, mr: 1 }}
                        />
                    </StyledListItemIcon>
                    <ListItemText>Unsuspend Account</ListItemText>
                </StyledMenuItem>,
                <StyledMenuItem onClick={onBlock} key="block">
                    <StyledListItemIcon>
                        <SvgIcon
                            component={CircleUncheckIcon}
                            viewBox="0 0 20 21"
                            sx={{ width: 14, height: 14, mr: 1 }}
                        />
                    </StyledListItemIcon>
                    <ListItemText>Block Account</ListItemText>
                </StyledMenuItem>
            );

            break;
        }

        case ClientStatus.Active:
        case ClientStatus.Inactive: {
            menu.push(
                <StyledMenuItem onClick={onSuspend} key="suspend">
                    <StyledListItemIcon>
                        <SvgIcon
                            component={DeleteIcon}
                            viewBox="0 0 12 13"
                            sx={{ width: 14, height: 14, mr: 1 }}
                        />
                    </StyledListItemIcon>
                    <ListItemText>Suspend Account</ListItemText>
                </StyledMenuItem>,
                <StyledMenuItem onClick={onBlock} key="block">
                    <StyledListItemIcon>
                        <SvgIcon
                            component={CircleUncheckIcon}
                            viewBox="0 0 20 21"
                            sx={{ width: 14, height: 14, mr: 1 }}
                        />
                    </StyledListItemIcon>
                    <ListItemText>Block Account</ListItemText>
                </StyledMenuItem>
            );

            break;
        }

        default: {
        }
    }

    return menu;
};

const ClientProfilePopover = ({
    userId,
    companyId,
    onEditClick,
    clientInfo,
    onDeleted,
    color = 'default', // primary
}) => {
    const location = useLocation();
    const [updateCompany] = useUpdateCompanyMutation();
    const [deleteCompany, { isSuccess: isSuccessDelete }] = useDeleteCompanyMutation();
    const [sendInvite] = useSendInviteMutation();
    const [deleteInvite] = useDeleteInviteMutation();

    const history = useHistory();
    const popoverRef = useRef(null);
    const popoverDialog = useDialog();

    const confirmationDialog = useDialog();
    const handleConfirmDelete = () => {
        confirmationDialog.handleClose();
        deleteCompany({ companyId });
    };

    useEffect(() => {
        if (isSuccessDelete && onDeleted) {
            onDeleted();
        }
    }, [isSuccessDelete]);

    const handleEdit = () => {
        popoverDialog.handleClose();
        onEditClick();
    };

    const handleDelete = () => {
        popoverDialog.handleClose();
        confirmationDialog.handleOpen();
    };

    const handleSendInvite = () => {
        popoverDialog.handleClose();
        sendInvite({ userId, companyId });
    };

    const handleCancelInvite = () => {
        popoverDialog.handleClose();
        deleteInvite({ userId, companyId });
    };

    const handleSuspend = () => {
        popoverDialog.handleClose();
        updateCompany({ status: 'SUSPENDED', companyId: companyId });
    };

    const handleUnsuspend = () => {
        popoverDialog.handleClose();
        updateCompany({ status: 'ACTIVE', companyId: companyId });
    };

    const handleBlock = () => {
        popoverDialog.handleClose();
        updateCompany({ status: 'BLOCKED', companyId: companyId });
        if (location.pathname !== 'clients') {
            history.push('/clients');
        }
    };

    if (clientInfo.status === 'BLOCKED') {
        return <Box sx={{ width: 40, height: 40 }} />;
    }

    return (
        <Box>
            <PopoverButton
                onClick={popoverDialog.handleOpen}
                popoverRef={popoverRef}
                color={color}
            />
            <Popover
                disableScrollLock
                open={popoverDialog.open}
                onClose={popoverDialog.handleClose}
                anchorEl={popoverRef.current}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <MenuList>
                    <PopoverMenu
                        client={clientInfo}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        onSuspend={handleSuspend}
                        onUnsuspend={handleUnsuspend}
                        onBlock={handleBlock}
                        onSendInvite={handleSendInvite}
                        onResendInvite={handleSendInvite}
                        onCancelInvite={handleCancelInvite}
                    />
                </MenuList>
            </Popover>
            <ConfirmActionModal
                title="Are you sure you want to delete this client?"
                open={confirmationDialog.open}
                onClose={confirmationDialog.handleClose}
                onConfirm={handleConfirmDelete}
            />
        </Box>
    );
};

const StyledMenuItem = styled(MenuItem)`
    &.MuiMenuItem-root {
        min-height: 40px;
    }
`;

const StyledListItemIcon = styled(ListItemIcon)`
    &.MuiListItemIcon-root {
        min-width: 24px;
    }
`;

const StyledSvgIcon = styled(SvgIcon)`
    path {
        fill: none;
    }
`;

export default ClientProfilePopover;
