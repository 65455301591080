import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

import ErrorButton from '../buttons/ErrorButton';
import OutlinedPrimaryButton from '../buttons/OutlinedPrimaryButton';

import { ReactComponent as CrossIcon } from '../../assets/svg/close.svg';

const UnsavedChangesWarning = ({ message, handlePromptConfirm, handlePromptDecline }) => {
    return (
        <Dialog disableEscapeKeyDown open={Boolean(message)} onClose={handlePromptDecline}>
            <DialogTitle sx={{ minHeight: 48 }}>
                <IconButton
                    onClick={handlePromptDecline}
                    sx={{ position: 'absolute', right: 20, top: 24 }}
                >
                    <SvgIcon
                        component={CrossIcon}
                        viewBox="0 0 20 21"
                        sx={{ width: 18, height: 18 }}
                    />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ minWidth: 450, py: 4, px: 8 }}>
                <Grid
                    container
                    spacing={2}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item>
                        <Typography variant="h3" sx={{ textAlign: 'center' }}>
                            {message}
                        </Typography>
                    </Grid>
                    <Grid item sx={{ mt: 1 }}>
                        <ErrorButton
                            disableElevation
                            sx={{ py: 1, px: 8, mr: 3 }}
                            onClick={handlePromptConfirm}
                            color="error"
                            variant="contained"
                        >
                            Leave
                        </ErrorButton>
                        <OutlinedPrimaryButton
                            sx={{ py: 1, px: 8 }}
                            variant="outlined"
                            onClick={handlePromptDecline}
                        >
                            Cancel
                        </OutlinedPrimaryButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default UnsavedChangesWarning;
