import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import useQuery from './useQuery';

const useFilter = (params = {}) => {
    const history = useHistory();
    const [total, setTotal] = useState(params.count);
    useEffect(() => {
        if (total !== params.count) {
            setTotal(params.count);
        }
    }, [params.count]);

    const {
        urlSearchParams,
        query: { search, limit, offset },
    } = useQuery({ limit: params.limit, offset: params.offset, search: params.search });

    const pagination = useMemo(() => ({ total, limit, offset }), [total, limit, offset]);
    const handleSearch = useCallback(
        (value) => {
            // prevent change after first load
            if (value === search) {
                return;
            }

            if (value) {
                urlSearchParams.set('search', value);
            } else {
                urlSearchParams.delete('search');
            }

            urlSearchParams.delete('offset');
            history.push({ search: urlSearchParams.toString() });
        },
        [urlSearchParams]
    );

    const handlePagination = useCallback(
        (value) => {
            urlSearchParams.set('limit', value.limit);
            urlSearchParams.set('offset', value.offset);
            history.push({ search: urlSearchParams.toString() });
        },
        [urlSearchParams]
    );

    return { search, limit, offset, total, pagination, handleSearch, handlePagination };
};

export default useFilter;
