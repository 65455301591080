import React, { useState } from 'react';
import { LayoutGroup } from 'framer-motion';
import Grid from '@mui/material/Grid';

import DnDBox from '../../../components/dnd/DnDBox';
import MotionLayout from '../../../components/MotionLayout/MotionLayout';

import ClientImageUpload from './ClientImageUpload';
import ClientImageCard from './ClientImageCard';

import { ReactComponent as PhotoIcon } from '../../../assets/svg/Photo.svg';

const ClientImage = ({
    company,
    images,
    type = 'PAGE',
    limit = 1,
    height = 111,
    width = 130,
    padding = 24,
}) => {
    const [files, setFiles] = useState([]);
    const handleLoad = (file) => {
        setFiles((files) => [...files, file]);
    };

    const handleLoaded = (file) => {
        setFiles((files) => files.filter((f) => f !== file));
    };

    const total = images.length + files.length;
    return (
        <LayoutGroup>
            <Grid container>
                {images.map((image) => (
                    <Grid item key={image.imageId} sx={{ width: width + padding, height: height }}>
                        <MotionLayout>
                            <ClientImageCard image={image} height={height} />
                        </MotionLayout>
                    </Grid>
                ))}
                {files.map((file) => (
                    <Grid item key={file.name} sx={{ width: width + padding, height: height }}>
                        <MotionLayout>
                            <ClientImageUpload
                                onLoaded={handleLoaded}
                                companyId={company.companyId}
                                file={file}
                                type={type}
                                height={height}
                                width={width}
                            />
                        </MotionLayout>
                    </Grid>
                ))}
                {total < limit && (
                    <Grid item sx={{ width: width + padding, height: height }}>
                        <MotionLayout transition={{ delay: 0.1 }}>
                            <DnDBox
                                onLoadFile={handleLoad}
                                maxFiles={limit - total}
                                icon={PhotoIcon}
                                viewBox="0 0 48 48"
                                width={width}
                                height={height}
                                text="Upload"
                            />
                        </MotionLayout>
                    </Grid>
                )}
            </Grid>
        </LayoutGroup>
    );
};

export default ClientImage;
