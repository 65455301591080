import React, { useMemo } from 'react';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { getFullName } from '../../../utils/users';
import { getFullAddress } from '../../../utils/companies';

const CompanyInfo = ({ company }) => {
    const companyAddress = useMemo(() => getFullAddress(company), [company]);
    const adminName = useMemo(() => getFullName(company.admin || {}), [company]);
    return (
        <StyledBox sx={{ minHeight: { lg: 300 } }}>
            <Typography variant="subtitle1">Company info</Typography>
            <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" component="span" color="primary">
                    Company Owner Name:{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    {adminName}
                </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" component="span" color="primary">
                    Company Owner Email:{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    {company.admin?.email}
                </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" component="span" color="primary">
                    Company Email:{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    {company.email}
                </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" component="span" color="primary">
                    Address:{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    {companyAddress}
                </Typography>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle1" component="span" color="primary">
                    Phone:{' '}
                </Typography>
                <Typography variant="body1" component="span">
                    {company.phone}
                </Typography>
            </Box>
        </StyledBox>
    );
};

const StyledBox = styled(Box)`
    padding: 24px;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: var(--box-shadow);
`;

export default CompanyInfo;
