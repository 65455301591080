import React, { useContext } from 'react';
import Box from '@mui/material/Box';

import CompanyBranding from './components/CompanyBranding';
import ClientContext from './components/ClientContext';
import MainContainer from './components/MainContainer';

const BrandingPage = () => {
    const company = useContext(ClientContext);
    return (
        <MainContainer>
            <Box sx={{ mt: 4 }}>
                <CompanyBranding company={company} />
            </Box>
        </MainContainer>
    );
};

export default BrandingPage;
