import React, { useEffect, useMemo } from 'react';
import { Prompt } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useUpdateCompanyMutation } from '../../../redux/companies/companies.api';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import TextField from '../../../components/inputs/TextField';
import LinearProgress from '../../../components/loaders/LinearProgress';

const validationSchema = yup.object().shape({
    notes: yup.string().nullable(),
});

const getCompanyValues = ({ notes }) => ({
    notes: notes || '',
});

const getCompanyData = ({ notes }) => ({
    notes: notes || null,
});

const CompanyNotes = ({ company }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [
        updateCompany,
        { data, isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, isError: isErrorUpdate },
    ] = useUpdateCompanyMutation();

    const companyId = company.companyId;
    const formik = useFormik({
        validationSchema,
        initialValues: useMemo(() => getCompanyValues(company), []),
        onSubmit: (values) => {
            const data = getCompanyData(values);
            updateCompany({ companyId, ...data });
        },
    });

    useEffect(() => {
        if (data) {
            formik.resetForm({ values: getCompanyValues(data) });
        }
    }, [isSuccessUpdate]);

    useEffect(() => {
        if (isErrorUpdate) {
            enqueueSnackbar('Failed update company', { variant: 'error' });
        }
    }, [isErrorUpdate]);

    return (
        <StyledBox>
            {isLoadingUpdate && <LinearProgress />}
            <Prompt
                message="You have unsaved changes, are you sure you want to leave?"
                when={formik.dirty}
            />
            <form onSubmit={formik.handleSubmit}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    Notes
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <TextField
                            multiline
                            minRows={4}
                            formik={formik}
                            name="notes"
                            placeholder="Notes go here"
                        />
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                        <PrimaryButton sx={{ py: 1, px: 5 }} type="submit">
                            SAVE
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </form>
        </StyledBox>
    );
};

const StyledBox = styled(Box)`
    padding: 24px;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: var(--box-shadow);
`;

export default CompanyNotes;
