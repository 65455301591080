import ReactQuill from 'react-quill';
import './quill-align.format';

// define unsafe methods
if (ReactQuill.prototype.componentWillReceiveProps) {
    const componentWillReceiveProps = ReactQuill.prototype.componentWillReceiveProps;
    delete ReactQuill.prototype.componentWillReceiveProps;
    ReactQuill.prototype.UNSAFE_componentWillReceiveProps = function (nextProps, nextContext) {
        componentWillReceiveProps.call(this, nextProps, nextContext);
    };
}

if (ReactQuill.prototype.componentWillUpdate) {
    const componentWillUpdate = ReactQuill.prototype.componentWillUpdate;
    delete ReactQuill.prototype.componentWillUpdate;
    ReactQuill.prototype.UNSAFE_componentWillUpdate = function (nextProps, nextState, nextContext) {
        componentWillUpdate.call(this, nextProps, nextState, nextContext);
    };
}

export default ReactQuill;
