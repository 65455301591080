import React, { useEffect, useMemo, useState } from 'react';
import { Prompt } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import {
    useDeleteMessageMutation,
    useUpdateCompanyMutation,
    useUploadMessageMutation,
} from '../../../redux/companies/companies.api';

import PrimaryButton from '../../../components/buttons/PrimaryButton';
import LinearProgress from '../../../components/loaders/LinearProgress';
import ColorPicker from '../../../components/ColorPicker/ColorPicker';

import ClientImage from './ClientImage';
import MuiTextField from '@mui/material/TextField';

const ImageType = {
    ICON: 'ICON',
    LOGO: 'LOGO',
    PAGE: 'PAGE',
    CONFIRMATION_PAGE: 'CONFIRMATION_PAGE',
};

const MessageType = {
    REGISTRATION: 'REGISTRATION',
    LOGIN: 'LOGIN',
};

const getImagesByType = (images, type) => images.filter((image) => image.type === type);
const getMessagesByType = (messages, type) =>
    messages ? messages.filter((message) => message.type === type) : [];

const colorHexRE = /^#([\da-f]{6})$/;
const validationSchema = yup.object().shape({
    primaryColor: yup.string().matches(colorHexRE, 'Must be a hexadecimal'),
    textColor: yup.string().matches(colorHexRE, 'Must be a hexadecimal'),
});

const getBrandingValues = ({ primaryColor, textColor }) => ({
    primaryColor: primaryColor || '',
    textColor: textColor || '',
});

const CompanyBranding = ({ company }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [
        updateCompany,
        { data, isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, isError: isErrorUpdate },
    ] = useUpdateCompanyMutation();
    const [uploadMessage] = useUploadMessageMutation();
    const [deleteMessage] = useDeleteMessageMutation();

    const imagesByTypes = useMemo(
        () => ({
            icon: getImagesByType(company.images, ImageType.ICON),
            logo: getImagesByType(company.images, ImageType.LOGO),
            page: getImagesByType(company.images, ImageType.PAGE),
            confirmationPage: getImagesByType(company.images, ImageType.CONFIRMATION_PAGE),
        }),
        [company]
    );

    const messagesByTypes = useMemo(
        () => ({
            registration: getMessagesByType(company.messages, MessageType.REGISTRATION),
            login: getMessagesByType(company.messages, MessageType.LOGIN),
        }),
        [company]
    );

    const [registrationMessage, setRegistrationMessage] = useState(
        messagesByTypes.registration[0]?.content
    );
    const [loginMessage, setLoginMessage] = useState(messagesByTypes.login[0]?.content);

    const handleRegistrationMessageChange = (event) => {
        setRegistrationMessage(event.target.value);
    };

    const handleLoginMessageChange = (event) => {
        setLoginMessage(event.target.value);
    };

    const handleSave = async () => {
        if (registrationMessage) {
            const formDataRegistration = new FormData();
            formDataRegistration.append('companyId', companyId);
            formDataRegistration.append('content', registrationMessage);
            formDataRegistration.append('type', MessageType.REGISTRATION);
            await uploadMessage({ companyId, formData: formDataRegistration });
        } else {
            const messageId = messagesByTypes.registration[0]?.messageId;
            if (messageId) {
                await deleteMessage({ companyId, messageId });
            }
        }

        if (loginMessage) {
            const formDataLogin = new FormData();
            formDataLogin.append('companyId', companyId);
            formDataLogin.append('content', loginMessage);
            formDataLogin.append('type', MessageType.LOGIN);
            await uploadMessage({ companyId, formData: formDataLogin });
        } else {
            const messageId = messagesByTypes.login[0]?.messageId;
            if (messageId) {
                await deleteMessage({ companyId, messageId });
            }
        }
    };

    const companyId = company.companyId;
    const formik = useFormik({
        validationSchema,
        initialValues: useMemo(() => getBrandingValues(company), []),
        onSubmit: (values) => updateCompany({ companyId, ...values }),
    });

    useEffect(() => {
        if (data) {
            formik.resetForm({ values: getBrandingValues(data) });
        }
    }, [isSuccessUpdate]);

    useEffect(() => {
        if (isErrorUpdate) {
            enqueueSnackbar('Failed update company', { variant: 'error' });
        }
    }, [isErrorUpdate]);

    return (
        <StyledBox>
            {isLoadingUpdate && <LinearProgress />}
            <Prompt
                message="You have unsaved changes, are you sure you want to leave?"
                when={formik.dirty}
            />
            <form onSubmit={formik.handleSubmit}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    Company branding
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={{ xs: 2, lg: 4 }}>
                            <Grid item sx={{ width: { xs: 127, lg: 143 } }}>
                                <Typography variant="subtitle2" sx={{ mb: 2 }}>
                                    Company Icon
                                </Typography>
                                <ClientImage
                                    width="100%"
                                    padding=""
                                    company={company}
                                    images={imagesByTypes.icon}
                                    type={ImageType.ICON}
                                />
                            </Grid>
                            <Grid item xs>
                                <Typography variant="subtitle2" sx={{ mb: 2 }}>
                                    Company Logo
                                </Typography>
                                <ClientImage
                                    width="100%"
                                    padding=""
                                    company={company}
                                    images={imagesByTypes.logo}
                                    type={ImageType.LOGO}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Box sx={{ mt: 1 }}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6}>
                                            <ColorPicker
                                                name="primaryColor"
                                                label="Primary Colour HEX"
                                                formik={formik}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <ColorPicker
                                                name="textColor"
                                                label="Text Colour HEX"
                                                formik={formik}
                                            />
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" sx={{ mb: 1.5 }}>
                                    Confirmation Page Image
                                </Typography>
                                <ClientImage
                                    width="100%"
                                    height={124}
                                    padding=""
                                    company={company}
                                    images={imagesByTypes.confirmationPage}
                                    type={ImageType.CONFIRMATION_PAGE}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="subtitle2" sx={{ mb: 2 }}>
                            Login Page Image
                        </Typography>
                        <ClientImage
                            width="100%"
                            height={371}
                            padding=""
                            company={company}
                            images={imagesByTypes.page}
                            type={ImageType.PAGE}
                        />
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ px: { xs: 0, md: 2 }, pl: { md: 0 }, pb: 2 }}
                        >
                            <StyledTextField
                                fullWidth
                                size="small"
                                name="registrationPageText"
                                label="Registration Page Text"
                                multiline
                                minRows={4}
                                value={registrationMessage}
                                onChange={handleRegistrationMessageChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            md={6}
                            sx={{ px: { xs: 0, md: 2 }, pr: { md: 0 }, pb: 2 }}
                        >
                            <StyledTextField
                                fullWidth
                                size="small"
                                name="loginPageText"
                                label="Login Page Text"
                                multiline
                                minRows={4}
                                value={loginMessage}
                                onChange={handleLoginMessageChange}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                        <PrimaryButton sx={{ py: 1, px: 5 }} type="submit" onClick={handleSave}>
                            SAVE
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </form>
        </StyledBox>
    );
};

const StyledBox = styled(Box)`
    padding: 24px;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: var(--box-shadow);
`;

const StyledTextField = styled(MuiTextField)`
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
        color: var(--color-text) !important;
        -webkit-box-shadow: 0 0 0 40px white inset;
    }
`;

export default CompanyBranding;
