import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import * as Sentry from '@sentry/react';

import App from './App';
import store from './redux/store';
import { commit, environment, sentryDSN } from './utils/environment';

import 'react-medium-image-zoom/dist/styles.css';
import './assets/styles/index.scss';

Sentry.init({ environment, dsn: sentryDSN, initialScope: { tags: { commit } } });

ReactDOM.render(
    <ReduxProvider store={store}>
        <App />
    </ReduxProvider>,
    document.getElementById('root')
);
