import styled from 'styled-components';
import { NavLink as RouterNavLink } from 'react-router-dom';

const NavLink = styled(RouterNavLink)`
    text-decoration: none;
    color: #212121;
`;

export const ExternalLink = styled.a`
  text-decoration: none;
  color: #212121;
`;

export default NavLink;
