class AuthService {
    static storage = localStorage;
    static key = 'token';

    static setToken(token) {
        this.storage.setItem(this.key, token);
    }

    static getToken() {
        return this.storage.getItem(this.key);
    }

    static removeToken() {
        this.storage.removeItem(this.key);
    }

    static isAuthenticated() {
        const token = this.getToken();
        return Boolean(token);
    }
}

export default AuthService;
