import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import BrandService from '../services/brand.service';
import { selectBranding } from '../redux/common/selectors';

export const useBrandTheme = () => {
    const branding = useSelector(selectBranding);

    useEffect(() => {
        if (branding) {
            BrandService.setTheme({
                primaryColor: branding.primaryColor,
                textColor: branding.textColor,
                images: branding.images,
            });
        }
    }, [branding]);

    return useMemo(
        () => ({
            primaryColor: BrandService.getPrimaryColor(branding),
            textColor: BrandService.getTextColor(branding),
            images: BrandService.getImages(branding),
        }),
        [branding]
    );
};

export default useBrandTheme;
