import { useState, useCallback } from 'react';

export const useDialog = (initialState = false) => {
    const [open, setOpen] = useState(initialState);
    const handleOpen = useCallback(() => {
        setOpen(true);
    }, [setOpen]);

    const handleClose = useCallback(() => {
        setOpen(false);
    }, [setOpen]);

    return { open, handleOpen, handleClose };
};

export default useDialog;
