import React, { useState } from 'react';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as ShowIcon } from '../../assets/svg/showPasswordIcon.svg';
import { ReactComponent as HideIcon } from '../../assets/svg/hidePasswordIcon.svg';

const PasswordField = ({
    formik,
    required,
    label = 'Password',
    name = 'password',
    placeholder = 'Enter password',
    autocomplete = 'off',
}) => {
    const [type, setType] = useState('password');
    const handleClickToggle = () => setType(type === 'password' ? 'text' : 'password');
    return (
        <StyledTextField
            fullWidth
            size="small"
            type={type}
            name={name}
            required={required}
            error={formik.errors[name] && formik.touched[name]}
            helperText={formik.touched[name] && formik.errors[name]}
            onChange={formik.handleChange}
            value={formik.values[name]}
            label={label}
            placeholder={placeholder}
            inputProps={{ form: { autocomplete } }}
            InputLabelProps={{ shrink: true }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton onClick={handleClickToggle}>
                            <SvgIcon
                                component={type === 'password' ? ShowIcon : HideIcon}
                                viewBox={type === 'password' ? '0 0 19 10' : '0 0 16 14'}
                                sx={{ width: 16, height: 16 }}
                            />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

const StyledTextField = styled(TextField)`
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
        color: var(--color-text) !important;
        -webkit-box-shadow: 0 0 0 40px white inset;
    }
`;

export default PasswordField;
