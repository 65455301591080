import styled from 'styled-components';
import Container from '@mui/material/Container';

const MainContainer = ({ children }) => {
    return <StyledContainer maxWidth={false}>{children}</StyledContainer>;
};

const StyledContainer = styled(Container)`
    padding: 40px 80px;
`;

export default MainContainer;
