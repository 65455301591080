export default class BrandService {
    static THEME_KEY = 'brand-theme';

    static defaultTheme = {
        primaryColor: '#9d2f92',
        textColor: '#9d2f92',
        images: [
            {
                type: 'ICON',
                location: '/img/company/icon.png',
            },
            {
                type: 'LOGO',
                location: '/img/company/logo.png',
            },
            {
                type: 'PAGE',
                location: '/img/company/page.jpg',
            },
        ],
    };

    static storage = localStorage;

    static setTheme(theme) {
        this.storage.setItem(this.THEME_KEY, JSON.stringify(theme));
    }

    static getTheme() {
        const theme = this.storage.getItem(this.THEME_KEY);
        if (!theme) {
            return this.defaultTheme;
        }

        try {
            return JSON.parse(theme);
        } catch (err) {
            return this.defaultTheme;
        }
    }

    static getPrimaryColor(brand) {
        if (brand && brand.primaryColor) {
            return brand.primaryColor;
        }

        const theme = this.getTheme();
        if (theme && theme.primaryColor) {
            return theme.primaryColor;
        }

        return this.defaultTheme.primaryColor;
    }

    static getTextColor(brand) {
        if (brand && brand.textColor) {
            return brand.textColor;
        }

        const theme = this.getTheme();
        if (theme && theme.textColor) {
            return theme.textColor;
        }

        return this.defaultTheme.textColor;
    }

    static getImages(brand) {
        if (brand && brand.images.length > 0) {
            return this.defaultTheme.images.map((image) => {
                return brand.images.find((brandImage) => brandImage.type === image.type) || image;
            });
        }

        const theme = this.getTheme();
        if (theme && theme.images.length > 0) {
            return this.defaultTheme.images.map((image) => {
                return theme.images.find((themeImage) => themeImage.type === image.type) || image;
            });
        }

        return this.defaultTheme.images;
    }
}
