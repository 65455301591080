import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useQuery = (defaultParams = {}) => {
    const { search } = useLocation();
    const urlSearchParams = useMemo(() => new URLSearchParams(search), [search]);
    const query = useMemo(() => {
        const params = { ...defaultParams };
        urlSearchParams.forEach((value, key) => {
            params[key] = value || defaultParams[key];
        });

        return params;
    }, [urlSearchParams]);

    return { urlSearchParams, query };
};

export default useQuery;
