import React from 'react';
import styled from 'styled-components';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';

import { useDeleteImageMutation } from '../../../redux/companies/companies.api';

import { ReactComponent as DeleteIcon } from '../../../assets/svg/removeImageIcon.svg';

const ClientImageCard = ({ image, height }) => {
    const [deleteImage, { isLoading }] = useDeleteImageMutation();
    const handleDelete = () => {
        deleteImage({ companyId: image.companyId, imageId: image.imageId });
    };

    return (
        <Card sx={{ position: 'relative' }}>
            <CardMedia
                component="img"
                height={height}
                image={image.location}
                alt={`img-${image.imageId}`}
            />
            <StyledIconButton disabled={isLoading} onClick={handleDelete}>
                <SvgIcon
                    className="svg-icon"
                    component={DeleteIcon}
                    viewBox="0 0 24 24"
                    sx={{ width: 24, height: 24 }}
                />
            </StyledIconButton>
        </Card>
    );
};

const StyledIconButton = styled(IconButton)`
    position: absolute;
    bottom: 12px;
    right: 12px;
    padding: 0;
    border: 0.5px solid var(--company-primary-color);
    background-color: #ffffff !important;
`;

export default ClientImageCard;
