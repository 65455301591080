import React, { useEffect, useState, useRef, useCallback, useMemo } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styled, { ThemeProvider as StyledThemeProvider } from 'styled-components';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider as MaterialThemeProvider, darken, lighten } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import useBrandTheme from './hooks/useBrandTheme';
import { selectIsAuth } from './redux/common/selectors';
import { useLazyGetBrandingQuery, useLazyGetIdentityQuery } from './redux/guests/guests.api';

import UnsavedChangesWarning from './components/modals/UnsavedChangesWarning';
import iconVariants from './components/Snackbar/iconVariants';
import SnackbarCloseButton from './components/SnackbarCloseButton/SnackbarCloseButton';

import Login from './pages/Login/Login';
import AccountRoutes from './pages/AccountRoutes';

import { materialTheme, styledTheme, GlobalStyle } from './theme';

const faviconElement = document.getElementById('favicon');

function App() {
    const isAuth = useSelector(selectIsAuth);
    const { primaryColor, textColor, images } = useBrandTheme();
    const [getBranding] = useLazyGetBrandingQuery();
    const [getIdentity] = useLazyGetIdentityQuery();
    const primaryColorLight = useMemo(() => lighten(primaryColor, 0.2), [primaryColor]);
    const primaryColorDark = useMemo(() => darken(primaryColor, 0.2), [primaryColor]);
    useEffect(() => {
        materialTheme.palette.primary.main = primaryColor;
        materialTheme.palette.primary.light = primaryColorLight;
        materialTheme.palette.primary.dark = primaryColorDark;
    }, [primaryColor]);

    useEffect(() => {
        if (isAuth) {
            getIdentity();
        } else {
            getBranding();
        }
    }, [isAuth]);

    useEffect(() => {
        const companyIcon = images.find((image) => image.type === 'ICON')?.location;
        if (companyIcon) {
            faviconElement.href = companyIcon;
        }
    }, [images]);

    const callbackRef = useRef(null);
    const [message, setMessage] = useState('');
    const handlePromptConfirm = useCallback(() => {
        callbackRef.current(true);
        setMessage('');
    }, []);

    const handlePromptDecline = useCallback(() => {
        callbackRef.current(false);
        setMessage('');
    }, []);

    return (
        <BrowserRouter
            getUserConfirmation={(message, callback) => {
                setMessage(message);
                callbackRef.current = callback;
            }}
        >
            <MaterialThemeProvider theme={materialTheme}>
                <StyledThemeProvider theme={styledTheme}>
                    <CssBaseline />
                    <GlobalStyle
                        primaryColor={primaryColor}
                        textColor={textColor}
                        primaryColorLight={primaryColorLight}
                        primaryColorDark={primaryColorDark}
                    />
                    <Main>
                        <SnackbarProvider
                            maxSnack={3}
                            autoHideDuration={5000}
                            iconVariant={iconVariants}
                            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                            action={(snackbarKey) => (
                                <SnackbarCloseButton snackbarKey={snackbarKey} />
                            )}
                        >
                            {isAuth ? (
                                <Switch>
                                    <Route path="/" component={AccountRoutes} />
                                </Switch>
                            ) : (
                                <Switch>
                                    <Route path="/login" component={Login} />
                                    <Redirect to="/login" />
                                </Switch>
                            )}
                        </SnackbarProvider>
                    </Main>
                    {message && (
                        <UnsavedChangesWarning
                            message={message}
                            handlePromptConfirm={handlePromptConfirm}
                            handlePromptDecline={handlePromptDecline}
                        />
                    )}
                </StyledThemeProvider>
            </MaterialThemeProvider>
        </BrowserRouter>
    );
}

const Main = styled.div`
    min-height: 100vh;
    width: 100%;
    background-color: #f8f8f8;

    & .SnackbarContent-root {
        flex-wrap: nowrap;
        max-width: 290px;
        background-color: white;
        color: var(--color-text);
    }

    & .SnackbarItem-variantInfo {
        border: 1px solid #6041af;
    }

    & .SnackbarItem-variantSuccess {
        border: 1px solid #02c438;
    }

    & .SnackbarItem-variantWarning {
        border: 1px solid #f89820;
    }

    & .SnackbarItem-variantError {
        border: 1px solid #d60000;
    }
`;

export default App;
