import React, { useCallback, useRef, useState, useEffect } from 'react';
import cn from 'classnames';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Paper from '@mui/material/Paper';
import FormHelperText from '@mui/material/FormHelperText';

import OutlinedPrimaryButton from '../buttons/OutlinedPrimaryButton';
import useDebounce from '../../hooks/useDebounce';

import CustomToolbar, { getRangeFormat } from './CustomToolbar';
import ContentEditor, { normalFontSize } from './ContentEditor';

import styles from './PreviewEditor.module.scss';

const PreviewEditor = ({ defaultContent, onChange, placeholder, uploadImage, helperText }) => {
    const reactQuillRef = useRef();
    const [preview, setPreview] = useState(defaultContent);
    const previewDebounced = useDebounce(preview, 300);
    const [previewMode, setPreviewMode] = useState(false);
    const handleTogglePreview = () => {
        setPreviewMode((state) => !state);
    };

    const [tabIndex, setTabIndex] = React.useState(0);
    const handleChangeTab = (event, index) => {
        setTabIndex(index);
    };

    useEffect(() => {
        if (typeof previewDebounced === 'undefined') {
            return;
        }

        onChange(previewDebounced);
    }, [previewDebounced]);

    const handleChangeContent = useCallback(
        (content) => {
            setPreview(content);
        },
        [onChange]
    );

    const [fontSize, setFontSize] = useState(normalFontSize.value);
    const onChangeSelection = useCallback((range) => {
        if (range) {
            const size = getRangeFormat(reactQuillRef, range, 'size');
            if (size && !Array.isArray(size)) {
                setFontSize(size);
            } else {
                setFontSize(normalFontSize.value);
            }
        }
    }, []);

    return (
        <>
            <Grid container>
                <Grid item xs>
                    <Grid container justifyContent="center">
                        <Grid item hidden={previewMode}>
                            <CustomToolbar
                                reactQuillRef={reactQuillRef}
                                fontSize={fontSize}
                                onSelectFontSize={setFontSize}
                            />
                        </Grid>
                        <Grid item md={6} xs hidden={!previewMode}>
                            {previewMode && (
                                <Tabs
                                    value={tabIndex}
                                    onChange={handleChangeTab}
                                    variant="fullWidth"
                                    TabIndicatorProps={{
                                        children: <span className="MuiTabs-indicatorSpan" />,
                                    }}
                                >
                                    <Tab label="Desktop" />
                                    <Tab label="Mobile" />
                                </Tabs>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <OutlinedPrimaryButton
                        variant="outlined"
                        sx={{ py: 1, px: 5 }}
                        onClick={handleTogglePreview}
                    >
                        {previewMode ? 'Edit' : 'Preview'}
                    </OutlinedPrimaryButton>
                </Grid>
            </Grid>
            <div hidden={previewMode}>
                <ContentEditor
                    ref={reactQuillRef}
                    uploadImage={uploadImage}
                    onChange={handleChangeContent}
                    defaultContent={defaultContent}
                    placeholder={placeholder}
                    onChangeSelection={onChangeSelection}
                />
                {helperText && <FormHelperText error>{helperText}</FormHelperText>}
            </div>
            {previewMode && (
                <Paper
                    variant="outlined"
                    sx={{ mt: 3, ml: 'auto', mr: 'auto', px: 5, py: 5, borderRadius: 2 }}
                    className={cn(styles.preview, tabIndex === 0 ? styles.desktop : styles.mobile)}
                >
                    <div dangerouslySetInnerHTML={{ __html: preview }} />
                </Paper>
            )}
        </>
    );
};

export default React.memo(PreviewEditor);
