import React, { useEffect, useMemo } from 'react';
import { Prompt } from 'react-router-dom';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import {
    useGetCompanyTaxesQuery,
    useUpdateCompanyTaxesMutation,
} from '../../redux/companyTaxes/companyTaxes.api';

import LinearProgress from '../../components/loaders/LinearProgress';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { PROVINCE } from '../../utils/taxes';

const validationSchema = yup.array().of(
    yup.object().shape({
        companyTaxId: yup.string().uuid().required(),
        province: yup.string().oneOf(Object.values(PROVINCE)).required(),
        taxRate: yup.number().min(0).max(100).required(),
        taxLabel: yup.string(),
    })
);

const getCompanyTaxValues = (companyTax) => ({
    companyTaxId: companyTax.companyTaxId,
    province: companyTax.province,
    taxRate: companyTax.taxRate,
    taxLabel: companyTax.taxLabel || '',
});

const getCompanyTaxesValues = (companyTaxes) => companyTaxes.map(getCompanyTaxValues);

const getCompanyTaxData = (values) => ({
    companyTaxId: values.companyTaxId,
    taxRate: values.taxRate,
    taxLabel: values.taxLabel,
});

const getCompanyTaxesData = (data) => data.map(getCompanyTaxData);

const CompanyTaxFields = ({ formik, index }) => {
    const errors = formik.errors[index];
    const values = formik.values[index];
    const touched = formik.touched[index];
    return (
        <Grid container spacing={4}>
            <Grid item sx={{ minWidth: { lg: 429 } }}>
                <TextField
                    required
                    disabled
                    fullWidth
                    type="text"
                    name={`${index}.province`}
                    error={errors?.province && touched?.province}
                    helperText={touched?.province && errors?.province}
                    onChange={formik.handleChange}
                    value={values.province}
                    InputLabelProps={{ shrink: true }}
                    label="Province"
                    size="small"
                />
            </Grid>
            <Grid item sx={{ maxWidth: 180 }}>
                <TextField
                    fullWidth
                    type="text"
                    name={`${index}.taxLabel`}
                    error={errors?.taxLabel && touched?.taxLabel}
                    helperText={touched?.taxLabel && errors?.taxLabel}
                    onChange={formik.handleChange}
                    value={values.taxLabel}
                    InputLabelProps={{ shrink: true }}
                    label="Tax Label"
                    size="small"
                />
            </Grid>
            <Grid item sx={{ maxWidth: 180 }}>
                <TextField
                    required
                    fullWidth
                    type="number"
                    name={`${index}.taxRate`}
                    error={errors?.taxRate && touched?.taxRate}
                    helperText={touched?.taxRate && errors?.taxRate}
                    onChange={formik.handleChange}
                    value={values.taxRate}
                    InputLabelProps={{ shrink: true }}
                    label="Tax Rate (%)"
                    size="small"
                />
            </Grid>
        </Grid>
    );
};

const TaxesForm = ({ companyTaxes }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [updateCompanyTaxes, { isLoading, isSuccess }] = useUpdateCompanyTaxesMutation();
    const formik = useFormik({
        validationSchema,
        initialValues: useMemo(() => getCompanyTaxesValues(companyTaxes), [companyTaxes]),
        onSubmit: (values) => {
            updateCompanyTaxes(getCompanyTaxesData(values));
        },
    });

    useEffect(() => {
        if (isSuccess) {
            enqueueSnackbar('Company taxes updated', { variant: 'success' });
        }
    }, [isSuccess]);

    useEffect(() => {
        if (companyTaxes) {
            formik.resetForm({ values: getCompanyTaxesValues(companyTaxes) });
        }
    }, [companyTaxes]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Prompt
                message="You have unsaved changes, are you sure you want to leave?"
                when={formik.dirty}
            />
            {isLoading && <LinearProgress />}
            <Grid container spacing={4} direction="column">
                <Grid item>
                    {formik.values.map((values, index) => (
                        <Box key={index} sx={{ mt: 4 }}>
                            <CompanyTaxFields formik={formik} index={index} />
                        </Box>
                    ))}
                </Grid>
                <Grid item>
                    <Grid container justifyContent="center" sx={{ maxWidth: { lg: 960 } }}>
                        <Grid item>
                            <PrimaryButton
                                type="submit"
                                disabled={isLoading}
                                sx={{ py: 1, px: 5, textTransform: 'none', minWidth: { lg: 185 } }}
                            >
                                Save
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

const Taxes = () => {
    const { data: companyTaxes, isLoading } = useGetCompanyTaxesQuery();
    return (
        <Box sx={{ py: 5, px: 10 }}>
            {isLoading && <LinearProgress />}
            <Typography component="h1" variant="h3">
                Taxes
            </Typography>
            {companyTaxes && (
                <Paper sx={{ mt: 4, p: 4 }}>
                    <TaxesForm companyTaxes={companyTaxes} />
                </Paper>
            )}
        </Box>
    );
};

export default Taxes;
