import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../helpers/axiosBaseQuery';
import { provideTags } from '../../utils/responses';

const TAG_TYPE_STATISTIC = 'STATISTIC';

export const widgetsApi = createApi({
    baseQuery: axiosBaseQuery(),
    reducerPath: 'widgetsApi',
    tagTypes: [TAG_TYPE_STATISTIC],
    endpoints: (build) => ({
        getStatistics: build.query({
            query: () => ({ url: `/api/v1.1/widgets/statistics` }),
            providesTags: provideTags(() => [{ type: TAG_TYPE_STATISTIC }]),
        }),
    }),
});

export const { useGetStatisticsQuery } = widgetsApi;

export default widgetsApi;
