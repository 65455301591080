import { Quill } from 'react-quill';

const Parchment = Quill.import('parchment');

const alignClass = new Parchment.Attributor.Class('align', 'ql-align', {
    scope: Parchment.Scope.BLOCK,
    whitelist: ['right', 'center', 'justify'],
});

alignClass.add = function (node, value) {
    if (!this.canAdd(node, value)) return false;
    node.style.textAlign = value;
    return true;
};

alignClass.remove = function (node) {
    node.style.removeProperty('text-align');
};

alignClass.value = function (node) {
    const value = node.style.textAlign;
    if (this.canAdd(node, value) && value) {
        return value;
    }
    return '';
};

Quill.register(alignClass, true);
