import React, { useRef } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import { resetAuth } from '../../redux/common/commonSlice';
import useDialog from '../../hooks/useDialog';
import AuthService from '../../services/AuthService';

import { ReactComponent as LogoutIcon } from '../../assets/svg/logoutIcon.svg';

const ProfileSettings = () => {
    const menuDialog = useDialog();
    const menuRef = useRef();
    const dispatch = useDispatch();
    const logoutHandler = () => {
        dispatch(resetAuth());
        AuthService.removeToken();
    };

    return (
        <Grid container alignItems="center" justifyContent="right">
            <Grid item>
                <Tooltip title="Account settings">
                    <IconButton onClick={menuDialog.handleOpen} size="small" ref={menuRef}>
                        <Avatar>MA</Avatar>
                    </IconButton>
                </Tooltip>
                <StyledMenu
                    disableScrollLock
                    anchorEl={menuRef.current}
                    open={menuDialog.open}
                    onClose={menuDialog.handleClose}
                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                    <MenuItem onClick={logoutHandler}>
                        <ListItemIcon>
                            <SvgIcon
                                component={LogoutIcon}
                                viewBox="0 0 17 16"
                                sx={{ width: 16, height: 16 }}
                            />
                        </ListItemIcon>
                        Log Out
                    </MenuItem>
                </StyledMenu>
            </Grid>
        </Grid>
    );
};

const StyledMenu = styled(Menu)`
    .MuiListItemIcon-root {
        min-width: 24px;
    }
`;

export default ProfileSettings;
