import React, { useEffect } from 'react';
import { Quill } from 'react-quill';
import ReactQuill from './quill/ReactQuill';
import MagicUrl from 'quill-magic-url';

import CustomLinkSanitizer from './quill/CustomLinkSanitizer';
import ImageUploader from './quill-image-uploader/quill.imageUploader';
import './quill-shortcode/quill.shortcode.blot';

import styles from './ContentEditor.module.scss';

const BlockEmbed = Quill.import('blots/block/embed');

class SvgIconBlot extends BlockEmbed {
    static create(value) {
        let node = super.create(value);
        node.innerHTML = value;
        node.setAttribute('contenteditable', false);
        return node;
    }

    static value(node) {
        return node.innerHTML;
    }
}

SvgIconBlot.blotName = 'svgIcon';
SvgIconBlot.tagName = 'div';
SvgIconBlot.className = 'svg-icon-blot';

Quill.register(SvgIconBlot, true);
Quill.register(CustomLinkSanitizer, true);
Quill.register('modules/magicUrl', MagicUrl);
Quill.register('modules/imageUploader', ImageUploader);

export const normalFontSize = {
    name: 'Normal',
    value: '16px',
};

export const namedFontSizes = [
    {
        name: 'Small',
        value: '12px',
    },
    normalFontSize,
    {
        name: 'Large',
        value: '24px',
    },
    {
        name: 'Huge',
        value: '40px',
    },
];

export const shortcodes = [
    {
        name: 'First name',
        value: '{{First name}}',
    },
    {
        name: 'Last name',
        value: '{{Last name}}',
    },
    {
        name: 'Building name',
        value: '{{Building name}}',
    },
    {
        name: 'Building address',
        value: '{{Building address}}',
    },
    {
        name: 'Building city',
        value: '{{Building city}}',
    },
    {
        name: 'Building province code',
        value: '{{Building province code}}',
    },
    {
        name: 'Building postal code',
        value: '{{Building postal code}}',
    },
    {
        name: 'Building phone',
        value: '{{Building phone}}',
    },
    {
        name: 'Building email',
        value: '{{Building email}}',
    },
    {
        name: 'Building website',
        value: '{{Building website}}',
    },
    {
        name: 'Unit number',
        value: '{{Unit number}}',
    },
    {
        name: 'Invitation link',
        value: '{{Invitation link}}',
    },
];

const Size = Quill.import('attributors/style/size');
Size.whitelist = namedFontSizes.map((namedFontSize) => namedFontSize.value);
Quill.register(Size, true);

let uploadFileMutation = null;
export const ContentEditor = (
    {
        defaultContent,
        onChange,
        onChangeSelection,
        uploadImage,
        placeholder = 'Start adding images/links...',
        theme = 'snow',
    },
    reactQuillRef
) => {
    const handleChangeSelection = (range) => {
        onChangeSelection(range);
    };

    useEffect(() => {
        uploadFileMutation = uploadImage;
        return () => (uploadFileMutation = null);
    }, [uploadImage]);

    return (
        <>
            <div id="toolbar-hidden" hidden />
            <ReactQuill
                preserveWhitespace
                className={styles.quillEditor}
                ref={reactQuillRef}
                theme={theme}
                defaultValue={defaultContent}
                onChange={onChange}
                onChangeSelection={handleChangeSelection}
                modules={ContentEditor.modules}
                formats={ContentEditor.formats}
                placeholder={placeholder}
            />
        </>
    );
};

const uploadFile = async (file) => {
    const data = await uploadFileMutation(file);
    return data?.location;
};

ContentEditor.modules = {
    toolbar: { container: '#toolbar-hidden' }, // toolbar is used to add image handler
    clipboard: { matchVisual: false },
    imageUploader: { upload: uploadFile },
    magicUrl: {
        urlRegularExpression: /(https?:\/\/[\S]+)|(www.[\S]+)|(tel:[\S]+)/g,
        globalRegularExpression: /(https?:\/\/|www\.)[\w-.]+\.[\w-.]+(\/([\S]+)?)?/gi,
    },
};

ContentEditor.formats = [
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'link',
    'image',
    'imageUploading',
    'shortcode',
    'align',
    'svgIcon',
];

export const ForwardRefContentEditor = React.forwardRef(ContentEditor);
export const MemoOfForwardRefContentEditor = React.memo(ForwardRefContentEditor);

export default MemoOfForwardRefContentEditor;
