import { useMemo } from 'react';
import styled from 'styled-components';
import MuiChip from '@mui/material/Chip';

const styles = { ml: 2, mt: -0.5, borderRadius: '4px' };
const Chip = ({ className, color, label, sx }) => {
    const combinedStyles = useMemo(() => ({ ...styles, ...sx }), [sx]);
    return (
        <MuiChip
            size="small"
            variant="outlined"
            color={color}
            label={label}
            className={className}
            sx={combinedStyles}
        />
    );
};

export default styled(Chip)`
    &.MuiChip-colorSuccess {
        color: #02c338;
    }

    &.MuiChip-outlinedSuccess {
        border: 1px solid #02c338;
        background: rgba(2, 196, 56, 0.05);
    }

    &.MuiChip-colorError {
        color: #d60000;
    }

    &.MuiChip-outlinedError {
        border: 1px solid #d60000;
        background: rgba(214, 0, 0, 0.05);
    }

    &.MuiChip-colorWarning {
        color: #f89820;
    }

    &.MuiChip-outlinedWarning {
        border: 1px solid #f89820;
        background: rgba(248, 152, 32, 0.05);
    }

    &.MuiChip-colorSecondary {
        color: #888888;
    }

    &.MuiChip-outlinedSecondary {
        border: 1px solid #888888;
        background: rgba(136, 136, 136, 0.05);
    }

    &.MuiChip-colorInfo {
        color: #1fb1d4;
    }

    &.MuiChip-outlinedInfo {
        border: 1px solid #1fb1d4;
        background: rgba(31, 177, 212, 0.05);
    }

    &.MuiChip-colorDefault {
        color: #870c0c;
    }

    &.MuiChip-outlinedDefault {
        border: 1px solid #870c0c;
        background: rgba(31, 177, 212, 0.05);
    }
`;
