import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import {
    useGetPandaDocConfigQuery,
    useUpdatePandaDocConfigMutation,
} from '../../../redux/companies/companies.api';

import LinearProgress from '../../../components/loaders/LinearProgress';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import TextField from '../../../components/inputs/TextField';
import PasswordField from '../../../components/inputs/PasswordField';

const validationSchema = yup.object().shape({
    apiKey: yup.string().max(64).required(),
    email: yup.string().email().required(),
});

const getPandaDocConfigValues = (pandaDoc = {}) => ({
    apiKey: pandaDoc.apiKey || '',
    email: pandaDoc.email || '',
});

const getPandaDocConfigData = (values) => ({
    apiKey: values.apiKey,
    email: values.email,
});

const PandaDocConfigForm = ({ pandaDocConfig, onSubmit }) => {
    const formik = useFormik({
        validationSchema,
        initialValues: getPandaDocConfigValues(pandaDocConfig),
        onSubmit: (values) => onSubmit(getPandaDocConfigData(values)),
    });

    useEffect(() => {
        if (pandaDocConfig) {
            formik.resetForm({ values: getPandaDocConfigValues(pandaDocConfig) });
        }
    }, [pandaDocConfig]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Prompt
                message="You have unsaved changes, are you sure you want to leave?"
                when={formik.dirty}
            />
            <Grid container spacing={3} direction="column">
                <Grid item sm={4}>
                    <PasswordField
                        required
                        formik={formik}
                        name="apiKey"
                        label="API Key"
                        placeholder="Enter api key"
                    />
                </Grid>
                <Grid item sm={4}>
                    <TextField
                        required
                        formik={formik}
                        type="email"
                        name="email"
                        label="Panda Doc Email"
                        placeholder="Enter team member email"
                    />
                </Grid>
                <Grid item>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <PrimaryButton sx={{ py: 1, px: 5 }} type="submit">
                                Save
                            </PrimaryButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

const PandaDocAccount = ({ company }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { data, isLoading } = useGetPandaDocConfigQuery({ companyId: company.companyId });
    const [updatePandaDocConfig, { isError: isErrorUpdate, isLoading: isLoadingUpdate }] =
        useUpdatePandaDocConfigMutation();

    const handleSubmit = (values) => {
        updatePandaDocConfig({ companyId: company.companyId, ...values });
    };

    useEffect(() => {
        if (isErrorUpdate) {
            enqueueSnackbar('Update account failed', { variant: 'error' });
        }
    }, [isErrorUpdate]);

    const pandaDocConfig = data || undefined;
    if (isLoading) {
        return <LinearProgress />;
    }

    return (
        <StyledBox>
            {isLoadingUpdate && <LinearProgress />}
            <Typography variant="subtitle1">Panda Doc Account</Typography>
            <Box sx={{ mt: 3 }}>
                <PandaDocConfigForm pandaDocConfig={pandaDocConfig} onSubmit={handleSubmit} />
            </Box>
        </StyledBox>
    );
};

const StyledBox = styled(Box)`
    padding: 24px;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: var(--box-shadow);
`;

export default PandaDocAccount;
