import { createGlobalStyle } from 'styled-components';
import { createTheme } from '@mui/material/styles';

export const styledTheme = {
    primary: 'red',
};

export const materialTheme = createTheme({
    typography: {
        fontFamily: [
            'Poppins',
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
        ].join(','),
        h1: { fontSize: 26, fontWeight: 400, lineHeight: 1.5 },
        h3: { fontSize: 18, fontWeight: 500, lineHeight: 1.5 },
        subtitle1: { fontSize: 14, fontWeight: 500, lineHeight: 1.5 },
        subtitle2: { fontSize: 14, fontWeight: 400, lineHeight: 1.1 },
        body1: { fontSize: 14, fontWeight: 400, lineHeight: 1.5 },
        body2: { fontSize: 12, fontWeight: 400, lineHeight: 1.1 },
    },
    components: {
        MuiPaper: {
            styleOverrides: {
                rounded: { borderRadius: '12px' },
                elevation1: {
                    boxShadow: '0px 1px 15px rgba(0, 0, 0, 0.04), 0px 1px 6px rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h2: { fontSize: '24px', lineHeight: '1rem' },
                body2: { fontSize: '0.75rem', lineHeight: '1rem' },
                paragraph: { marginBottom: 0 },
            },
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderRadius: 0,
                },
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: 'var(--company-text-color)',
                    '&.Mui-focused': { color: 'var(--company-text-color)' },
                    '&.Mui-disabled': { color: 'var(--company-text-color)' },
                },
            },
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: { color: 'var(--color-black)' },
            },
        },
        MuiDivider: {
            styleOverrides: {
                root: { borderColor: 'var(--border-color)' },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '& .MuiTabs-indicator': {
                        display: 'flex',
                        justifyContent: 'center',
                        backgroundColor: 'transparent',
                    },
                    '& .MuiTabs-indicatorSpan': {
                        width: 'calc(100% - 32px)',
                        background:
                            'linear-gradient(90deg, var(--company-primary-color-dark) 0%, var(--company-primary-color-light ) 100%)',
                    },
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': { color: 'var(--color-black)' },
                },
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: { maxWidth: 'none', borderRadius: 0 },
            },
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    borderRadius: 10,
                    boxShadow: '0 1px 15px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.04)',
                },
            },
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    '& .MuiTableRow-root': {
                        backgroundColor: 'white',
                        boxShadow:
                            '0px 1px 15px rgba(0, 0, 0, 0.04), 0px 1px 6px rgba(0, 0, 0, 0.04)',
                    },
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    fontWeight: 500,
                },
                root: {
                    fontSize: 14,
                    lineHeight: '21px',
                    borderBottom: 'none',
                },
            },
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
    },
});

export const GlobalStyle = createGlobalStyle`
  :root {
    --color-text: #212121;
    --color-text-muted: #858585;
    --border-color: #e0e6ed;
    --color-text-secondary: #3b4857;
    --color-tooltip: #3b4857;
    --color-error: #d60000;
    --box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.04), 0px 1px 6px rgba(0, 0, 0, 0.04);
    --box-shadow-small: 0px 1px 5px rgba(0, 0, 0, 0.04), 0px 1px 2px rgba(0, 0, 0, 0.04);
    --company-primary-color: ${(props) => props.primaryColor};
    --company-primary-color-light: ${(props) => props.primaryColorLight};
    --company-primary-color-dark: ${(props) => props.primaryColorDark};
    --company-text-color: ${(props) => props.textColor};
  }
`;
