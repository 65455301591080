import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import TextField from '@mui/material/TextField';

import useDebounce from '../../hooks/useDebounce';

import { ReactComponent as SearchIcon } from '../../assets/svg/search.svg';

const SEARCH_DELAY = 500;

export const Search = ({ search, onSearch }) => {
    const [value, setValue] = useState(search);
    const valueDebounced = useDebounce(value, SEARCH_DELAY);
    const handleChangeSearch = (event) => {
        setValue(event.target.value);
    };

    useEffect(() => {
        if (value !== search) {
            setValue(search);
        }
    }, [search]);

    useEffect(() => {
        onSearch(valueDebounced);
    }, [valueDebounced]);

    return (
        <SearchField
            size="small"
            placeholder="Search"
            value={value}
            onChange={handleChangeSearch}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton disabled>
                            <SvgIcon
                                component={SearchIcon}
                                viewBox="0 0 14 14"
                                sx={{ width: 14, height: 14 }}
                            />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    );
};

const SearchField = styled(TextField)`
    .MuiInputBase-input {
        width: 90px;
    }

    .MuiOutlinedInput-notchedOutline {
        border-radius: 22px;
    }
`;

export default Search;
