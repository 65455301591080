import React, { useEffect, useMemo, useContext } from 'react';
import styled from 'styled-components';
import { useLocation, useHistory } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';

import { useUpdateCompanyMutation } from '../../../redux/companies/companies.api';
import useDialog from '../../../hooks/useDialog';
import { baseProtocol, baseHost } from '../../../utils/domains';
import Chip from '../../../components/Chip/Chip';
import NavLink, { ExternalLink } from '../../../components/NavLink/NavLink';

import ClientProfilePopover from '../components/ClientProfilePopover';
import ClientFormModal from '../components/ClientFormModal';
import ClientContext from '../components/ClientContext';

const statusColors = {
    ACTIVE: 'success',
    INACTIVE: 'secondary',
    PENDING: 'warning',
    SUSPENDED: 'error',
    DRAFT: 'secondary',
    BLOCKED: 'default',
};

const SubNavBar = () => {
    const company = useContext(ClientContext);
    const { companyId } = company;
    const [
        updateCompany,
        { error: errorUpdate, isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate },
    ] = useUpdateCompanyMutation();

    const history = useHistory();
    const editDialog = useDialog();
    const handleDeleted = () => {
        history.push('/clients');
    };

    const handleSubmitEdit = (values) => {
        updateCompany({ companyId, ...values });
    };

    const location = useLocation();
    const navs = useMemo(
        () => [
            {
                label: 'Dashboard',
                to: `/clients/${companyId}/dashboard`,
            },
            {
                label: 'Campaigns',
                to: `/clients/${companyId}/campaigns`,
            },
            {
                label: 'Branding',
                to: `/clients/${companyId}/branding`,
            },
        ],
        [companyId]
    );

    const activeTabIndex = useMemo(() => {
        const index = navs.findIndex((nav) => nav.to === location.pathname);
        return index === -1 ? false : index;
    }, [location]);

    useEffect(() => {
        if (isSuccessUpdate) {
            editDialog.handleClose();
        }
    }, [isSuccessUpdate]);

    const domain = `${company.subdomain?.name}.${baseHost}`;
    return (
        <Box sx={{ mt: '40px', mx: { xs: '24px', sm: '40px', md: '80px' } }}>
            <Grid container>
                <Grid item xs>
                    <Typography variant="h1">
                        {company.companyName}
                        <Chip color={statusColors[company.status]} label={company.status} />
                    </Typography>
                    <Typography variant="h3">
                        <ExternalLink href={`${baseProtocol}//${domain}`} target="_blank">
                            {domain}
                        </ExternalLink>
                    </Typography>
                </Grid>
                <Grid item>
                    <ClientProfilePopover
                        color="primary"
                        onDeleted={handleDeleted}
                        userId={company.admin?.userId}
                        companyId={company.companyId}
                        clientInfo={company}
                        onEditClick={editDialog.handleOpen}
                    />
                    {editDialog.open && (
                        <ClientFormModal
                            open={editDialog.open}
                            errorResponse={errorUpdate}
                            client={company}
                            isLoading={isLoadingUpdate}
                            onClose={editDialog.handleClose}
                            onSubmit={handleSubmitEdit}
                        />
                    )}
                </Grid>
            </Grid>
            <Wrapper>
                <Tabs
                    value={activeTabIndex}
                    TabIndicatorProps={{
                        children: <span className="MuiTabs-indicatorSpan" />,
                    }}
                >
                    {navs.map((nav) => (
                        <Tab
                            key={nav.label}
                            component={NavLink}
                            label={nav.label}
                            to={nav.to}
                            style={{ textTransform: 'capitalize' }}
                        />
                    ))}
                </Tabs>
            </Wrapper>
        </Box>
    );
};

const Wrapper = styled.div`
    margin-top: 24px;
    border-bottom: 1px solid #d7d7d7;
`;

export default SubNavBar;
