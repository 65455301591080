import { useEffect, useRef, useState } from 'react';

const useSize = (initialState = {}) => {
    const [width, setWidth] = useState(initialState.width);
    const [height, setHeight] = useState(initialState.height);
    const boxRef = useRef(null);

    useEffect(() => {
        if (boxRef.current) {
            setWidth(boxRef.current.offsetWidth);
            setHeight(boxRef.current.offsetWidth);
        }
    }, [boxRef.current]);

    return { boxRef, width, height };
};

export default useSize;
