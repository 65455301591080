import React from 'react';
import styled from 'styled-components';

import Chip from '../../../components/Chip/Chip';

const statusLabels = {
    DRAFT: 'DRAFT',
    IN_PROGRESS: 'IN PROGRESS',
    COMPLETED: 'COMPLETED',
};

const statusColors = {
    DRAFT: 'secondary',
    IN_PROGRESS: 'warning',
    COMPLETED: 'success',
};

export const StatusLabel = ({ building }) => {
    return (
        <StyledChip color={statusColors[building.status]} label={statusLabels[building.status]} />
    );
};

const StyledChip = styled(Chip)({
    height: 18,
    fontFamily: 'Montserrat',
    fontSize: 10,
    fontWeight: 700,
    borderRadius: 4,
    lineHeight: '12px',
});

export default StatusLabel;
