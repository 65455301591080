import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../helpers/axiosBaseQuery';
import { invalidateTags, provideTags } from '../../utils/responses';

const TAG_TYPE_COMPANY_TAX = 'COMPANY_TAX';

export const companyTaxesApi = createApi({
    baseQuery: axiosBaseQuery(),
    reducerPath: 'companyTaxesApi',
    tagTypes: [TAG_TYPE_COMPANY_TAX],
    endpoints: (build) => ({
        getCompanyTaxes: build.query({
            query: () => ({ url: '/api/v1.1/company-taxes' }),
            providesTags: provideTags(() => [{ type: TAG_TYPE_COMPANY_TAX }]),
        }),
        updateCompanyTaxes: build.mutation({
            query: (queryArg) => ({
                url: '/api/v1.1/company-taxes',
                method: 'PATCH',
                data: queryArg,
            }),
            invalidatesTags: invalidateTags(() => [{ type: TAG_TYPE_COMPANY_TAX }]),
        }),
    }),
});

export const { useGetCompanyTaxesQuery, useUpdateCompanyTaxesMutation } = companyTaxesApi;

export default companyTaxesApi;
