import React, { useCallback, useMemo, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import styled from 'styled-components';
import Box from '@mui/material/Box';
import MuiTextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Popover from '@mui/material/Popover';

import useDialog from '../../hooks/useDialog';

const ColorAdornment = ({ color, onClick }) => {
    const backgroundColor = useMemo(() => (color.length === 7 ? color : '#000'), [color]);
    return (
        <InputAdornment position="start" onClick={onClick} sx={{ cursor: 'pointer' }}>
            <Box sx={{ width: 14, height: 14, backgroundColor }} />
        </InputAdornment>
    );
};

const ColorPicker = ({ formik, required, disabled, name, label, placeholder, type }) => {
    const boxRef = useRef();
    const [color, setColor] = useState(formik.values[name]);
    const popoverDialog = useDialog();
    const handleChangeText = (event) => {
        const { value } = event.target;
        formik.setFieldValue(name, value);
        setColor(value);
        if (popoverDialog.open) {
            popoverDialog.handleClose();
        }
    };

    const handleChange = useCallback(({ hex }) => {
        setColor(hex);
    }, []);

    const handleChangeComplete = useCallback(({ hex }) => {
        setColor(hex);
        formik.setFieldValue(name, hex);
    }, []);

    return (
        <>
            <StyledTextField
                fullWidth
                size="small"
                type={type}
                name={name}
                disabled={disabled}
                required={required}
                error={formik.errors[name] && formik.touched[name]}
                helperText={formik.touched[name] && formik.errors[name]}
                onChange={handleChangeText}
                value={formik.values[name]}
                label={label}
                placeholder={placeholder}
                InputProps={{
                    startAdornment: (
                        <ColorAdornment
                            color={formik.values[name]}
                            onClick={popoverDialog.handleOpen}
                        />
                    ),
                }}
                InputLabelProps={{ shrink: true }}
            />
            <Box ref={boxRef}>
                <Popover
                    open={popoverDialog.open}
                    anchorEl={boxRef.current}
                    onClose={popoverDialog.handleClose}
                >
                    <ChromePicker
                        disableAlpha
                        color={color}
                        onChange={handleChange}
                        onChangeComplete={handleChangeComplete}
                    />
                </Popover>
            </Box>
        </>
    );
};

const StyledTextField = styled(MuiTextField)`
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
        color: var(--color-text) !important;
        -webkit-box-shadow: 0 0 0 40px white inset;
    }
`;

export default ColorPicker;
