import React from 'react';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';

import PrimaryButton from '../buttons/PrimaryButton';
import OutlinedPrimaryButton from '../buttons/OutlinedPrimaryButton';
import LinearProgress from '../loaders/LinearProgress';

import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg';

const OptionalActions = ({ isLoading, submitLabel, cancelLabel, onClose }) => {
    return (
        <DialogActions sx={{ pb: 4, px: 4 }}>
            <Grid container spacing={1.5} justifyContent="center">
                <Grid item xs={6}>
                    <PrimaryButton
                        fullWidth
                        type="submit"
                        sx={{ minWidth: 135 }}
                        disabled={isLoading}
                    >
                        {submitLabel}
                    </PrimaryButton>
                </Grid>
                <Grid item xs={6}>
                    <OutlinedPrimaryButton
                        autoFocus
                        fullWidth
                        onClick={onClose}
                        variant="outlined"
                        sx={{ minWidth: 135 }}
                    >
                        {cancelLabel}
                    </OutlinedPrimaryButton>
                </Grid>
            </Grid>
        </DialogActions>
    );
};

const SingleAction = ({ isLoading, submitLabel }) => {
    return (
        <DialogActions sx={{ pb: 4, px: 4 }}>
            <PrimaryButton type="submit" sx={{ minWidth: 135 }} disabled={isLoading}>
                {submitLabel}
            </PrimaryButton>
        </DialogActions>
    );
};

export const FormModal = ({
    open,
    isLoading,
    onSubmit,
    onClose,
    children,
    maxWidth,
    title = 'Add',
    submitLabel = 'Add',
    cancelLabel = '',
    minWidth = { sm: 450, md: 590 },
}) => {
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }

        onClose(event, reason);
    };

    return (
        <Dialog disableEscapeKeyDown onClose={handleClose} open={open} maxWidth={maxWidth}>
            {isLoading && <LinearProgress position="absolute" />}
            <form onSubmit={onSubmit}>
                <DialogTitle sx={{ p: 4 }}>
                    {title}
                    <IconButton onClick={onClose} sx={{ position: 'absolute', right: 28, top: 24 }}>
                        <SvgIcon
                            component={CloseIcon}
                            viewBox="0 0 20 20"
                            sx={{ width: 24, height: 24 }}
                        />
                    </IconButton>
                </DialogTitle>
                <DialogContent
                    dividers
                    sx={{
                        pb: 4,
                        px: 4,
                        // https://github.com/mui/material-ui/pull/26323/files#diff-1855de53d4566b120fe890b43336daefc13a93097460d865ceed749c84808d77R35
                        // Fix case with dividers and selector .MuiDialogTitle-root + .MuiDialogContent-root
                        pt: '32px !important',
                        borderBottomColor: 'transparent',
                        minWidth: minWidth,
                    }}
                >
                    {children}
                </DialogContent>
                {cancelLabel ? (
                    <OptionalActions
                        isLoading={isLoading}
                        submitLabel={submitLabel}
                        cancelLabel={cancelLabel}
                        onClose={onClose}
                    />
                ) : (
                    <SingleAction isLoading={isLoading} submitLabel={submitLabel} />
                )}
            </form>
        </Dialog>
    );
};

export default FormModal;
