import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../helpers/axiosBaseQuery';
import { invalidateTags, provideTags } from '../../utils/responses';

const ID_LIST = 'LIST';

const TAG_TYPE_COMPANY = 'COMPANY';
const TAG_TYPE_COMPANY_SUBSCRIPTION = 'SUBSCRIPTION';
const TAG_TYPE_COMPANY_BUILDING = 'BUILDING';
const TAG_TYPE_PANDA_DOC_CONFIG = 'PANDA_DOC_CONFIG';

export const companiesApi = createApi({
    baseQuery: axiosBaseQuery(),
    reducerPath: 'companiesApi',
    tagTypes: [
        TAG_TYPE_COMPANY,
        TAG_TYPE_COMPANY_SUBSCRIPTION,
        TAG_TYPE_COMPANY_BUILDING,
        TAG_TYPE_PANDA_DOC_CONFIG,
    ],
    endpoints: (build) => ({
        findCompanies: build.query({
            query: ({ search, limit, offset }) => ({
                url: `/api/v1.1/companies`,
                params: { search, limit, offset },
            }),
            transformResponse: (res) => res.data,
            providesTags: provideTags(({ result }) => [
                { type: TAG_TYPE_COMPANY, id: ID_LIST },
                ...result.rows.map((company) => ({
                    type: TAG_TYPE_COMPANY,
                    id: company.companyId,
                })),
            ]),
        }),
        getCompany: build.query({
            query: (queryArg) => ({
                url: `/api/v1.1/companies/${queryArg.companyId}`,
                method: 'GET',
            }),
            providesTags: provideTags(({ queryArg }) => [
                { type: TAG_TYPE_COMPANY, id: queryArg.companyId },
            ]),
        }),
        getCompanyStatistics: build.query({
            query: (queryArg) => ({
                url: `/api/v1.1/companies/${queryArg.companyId}/statistics`,
                method: 'GET',
            }),
            providesTags: provideTags(({ queryArg }) => [
                { type: TAG_TYPE_COMPANY, id: queryArg.companyId },
            ]),
        }),
        getCompanySubscription: build.query({
            query: (queryArg) => ({
                url: `/api/v1.1/companies/${queryArg.companyId}/subscription`,
                method: 'GET',
            }),
            providesTags: provideTags(({ queryArg }) => [
                { type: TAG_TYPE_COMPANY_SUBSCRIPTION, id: queryArg.companyId },
            ]),
        }),
        createCompany: build.mutation({
            query: (queryArg) => ({ url: `/api/v1.1/companies`, method: 'POST', data: queryArg }),
            invalidatesTags: invalidateTags(() => [{ type: TAG_TYPE_COMPANY, id: ID_LIST }]),
        }),
        updateCompany: build.mutation({
            query: (queryArg) => ({
                url: `/api/v1.1/companies/${queryArg.companyId}`,
                method: 'PATCH',
                data: queryArg,
            }),
            transformResponse: (res) => res.data,
            invalidatesTags: invalidateTags(({ queryArg }) => [
                { type: TAG_TYPE_COMPANY, id: queryArg.companyId },
            ]),
        }),
        deleteCompany: build.mutation({
            query: (queryArg) => ({
                url: `/api/v1.1/companies/${queryArg.companyId}`,
                method: 'DELETE',
            }),
            invalidatesTags: invalidateTags(() => [{ type: TAG_TYPE_COMPANY, id: ID_LIST }]),
        }),
        uploadMessage: build.mutation({
            query: ({ companyId, formData }) => ({
                url: `/api/v1.1/companies/${companyId}/messages`,
                method: 'POST',
                data: formData,
            }),
            invalidatesTags: invalidateTags(({ queryArg }) => [
                { type: TAG_TYPE_COMPANY, id: queryArg.companyId },
            ]),
        }),
        deleteMessage: build.mutation({
            query: ({ companyId, messageId }) => ({
                url: `/api/v1.1/companies/${companyId}/messages/${messageId}`,
                method: 'DELETE',
            }),
            invalidatesTags: invalidateTags(({ queryArg }) => [
                { type: TAG_TYPE_COMPANY, id: queryArg.companyId },
            ]),
        }),
        uploadImage: build.mutation({
            query: ({ companyId, formData }) => ({
                url: `/api/v1.1/companies/${companyId}/images`,
                method: 'POST',
                data: formData,
            }),
            invalidatesTags: invalidateTags(({ queryArg }) => [
                { type: TAG_TYPE_COMPANY, id: queryArg.companyId },
            ]),
        }),
        deleteImage: build.mutation({
            query: ({ companyId, imageId }) => ({
                url: `/api/v1.1/companies/${companyId}/images/${imageId}`,
                method: 'DELETE',
            }),
            invalidatesTags: invalidateTags(({ queryArg }) => [
                { type: TAG_TYPE_COMPANY, id: queryArg.companyId },
            ]),
        }),
        getCompanyBuildings: build.query({
            query: (queryArg) => ({
                url: `/api/v1.1/companies/${queryArg.companyId}/buildings`,
                method: 'GET',
            }),
            transformResponse: (res) => res.data,
            providesTags: provideTags(({ queryArg }) => [
                { type: TAG_TYPE_COMPANY_BUILDING, id: queryArg.companyId },
            ]),
        }),
        updateCompanyBuilding: build.mutation({
            query: (queryArg) => ({
                url: `/api/v1.1/companies/${queryArg.companyId}/buildings/${queryArg.buildingId}`,
                method: 'PATCH',
                data: queryArg,
            }),
            invalidatesTags: invalidateTags(({ queryArg }) => [
                { type: TAG_TYPE_COMPANY_BUILDING, id: queryArg.companyId },
            ]),
        }),

        // invitations
        sendInvite: build.mutation({
            query: (queryArg) => ({
                url: `/api/v1.1/users/${queryArg.userId}/invitations`,
                method: 'POST',
            }),
            invalidatesTags: invalidateTags(({ queryArg }) => [
                { type: TAG_TYPE_COMPANY, id: queryArg.companyId },
            ]),
        }),
        deleteInvite: build.mutation({
            query: (data) => ({
                url: `/api/v1.1/users/${data.userId}/invitations`,
                method: 'DELETE',
            }),
            invalidatesTags: invalidateTags(({ queryArg }) => [
                { type: TAG_TYPE_COMPANY, id: queryArg.companyId },
            ]),
        }),

        // PandaDoc config
        getPandaDocConfig: build.query({
            query: (queryArg) => ({
                url: `/api/v1.1/companies/${queryArg.companyId}/panda-docs/config`,
            }),
            providesTags: provideTags(({ queryArg }) => [
                { type: TAG_TYPE_PANDA_DOC_CONFIG, id: queryArg.companyId },
            ]),
        }),
        updatePandaDocConfig: build.mutation({
            query: (queryArg) => ({
                url: `/api/v1.1/companies/${queryArg.companyId}/panda-docs/config`,
                method: 'POST',
                data: queryArg,
            }),
            invalidatesTags: invalidateTags(({ queryArg }) => [
                { type: TAG_TYPE_PANDA_DOC_CONFIG, id: queryArg.companyId },
            ]),
        }),
    }),
});

export const {
    useLazyFindCompaniesQuery,
    useGetCompanyQuery,
    useGetCompanyStatisticsQuery,
    useGetCompanySubscriptionQuery,
    useCreateCompanyMutation,
    useUpdateCompanyMutation,
    useDeleteCompanyMutation,
    useGetCompanyBuildingsQuery,
    useUpdateCompanyBuildingMutation,
    useUploadMessageMutation,
    useDeleteMessageMutation,
    useUploadImageMutation,
    useDeleteImageMutation,
    useDeleteInviteMutation,
    useSendInviteMutation,

    // PandaDoc config
    useGetPandaDocConfigQuery,
    useUpdatePandaDocConfigMutation,
} = companiesApi;

export default companiesApi;
