import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../helpers/axiosBaseQuery';
import { provideTags, invalidateTags } from '../../utils/responses';

const ID_LIST = 'LIST';

const TAG_TYPE_EMAIL_TEMPLATE = 'EMAIL_TEMPLATE';

export const emailTemplatesApi = createApi({
    baseQuery: axiosBaseQuery(),
    reducerPath: 'emailTemplatesApi',
    tagTypes: [TAG_TYPE_EMAIL_TEMPLATE],
    endpoints: (build) => ({
        getEmailTemplates: build.query({
            query: () => ({ url: `/api/v1.1/email-templates` }),
            transformResponse: (res) => res.data,
            providesTags: provideTags(({ result }) => [
                { type: TAG_TYPE_EMAIL_TEMPLATE, id: ID_LIST },
                ...result.map(({ emailTemplateId }) => ({
                    type: TAG_TYPE_EMAIL_TEMPLATE,
                    id: emailTemplateId,
                })),
            ]),
        }),
        getEmailTemplate: build.query({
            query: (queryArg) => ({ url: `/api/v1.1/email-templates/${queryArg.emailTemplateId}` }),
            providesTags: provideTags(({ queryArg }) => [
                { type: TAG_TYPE_EMAIL_TEMPLATE, id: queryArg.emailTemplateId },
            ]),
        }),
        updateEmailTemplate: build.mutation({
            query: (queryArg) => ({
                url: `/api/v1.1/email-templates/${queryArg.emailTemplateId}`,
                method: 'PATCH',
                data: queryArg,
            }),
            invalidatesTags: invalidateTags(({ queryArg }) => [
                { type: TAG_TYPE_EMAIL_TEMPLATE, id: queryArg.emailTemplateId },
            ]),
        }),
        uploadEmailTemplateImage: build.mutation({
            query: ({ emailTemplateId, formData }) => ({
                url: `/api/v1.1/email-templates/${emailTemplateId}/images`,
                method: 'POST',
                data: formData,
            }),
        }),
    }),
});

export const {
    useGetEmailTemplatesQuery,
    useGetEmailTemplateQuery,
    useUpdateEmailTemplateMutation,
    useUploadEmailTemplateImageMutation,
} = emailTemplatesApi;

export default emailTemplatesApi;
