import React from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';

import LinearProgress from '../../components/loaders/LinearProgress';
import { useGetCompanyQuery } from '../../redux/companies/companies.api';

import ClientContext from './components/ClientContext';
import SubNavBar from './components/SubNavBar';
import DashboardPage from './DashboardPage';
import CampaignsPage from './CampaignsPage';
import BrandingPage from './BrandingPage';

export const ClientRoutes = () => {
    const { companyId } = useParams();
    const { data: company, isLoading } = useGetCompanyQuery({ companyId });
    if (!company) {
        return isLoading ? <LinearProgress /> : null;
    }

    return (
        <ClientContext.Provider value={company}>
            <SubNavBar />
            <Switch>
                <Route exact path="/clients/:companyId/dashboard" component={DashboardPage} />
                <Route exact path="/clients/:companyId/campaigns" component={CampaignsPage} />
                <Route exact path="/clients/:companyId/branding" component={BrandingPage} />
                <Redirect to="/clients/:companyId/dashboard" />
            </Switch>
        </ClientContext.Provider>
    );
};

export default ClientRoutes;
