import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../helpers/axiosBaseQuery';
import { invalidateTags, provideTags } from '../../utils/responses';

const TAG_TYPE_ROOM = 'ROOM';

export const roomsApi = createApi({
    baseQuery: axiosBaseQuery(),
    reducerPath: 'roomsApi',
    tagTypes: [TAG_TYPE_ROOM],
    endpoints: (build) => ({
        getRooms: build.query({
            query: () => ({ url: '/api/v1.1/rooms' }),
            providesTags: provideTags(({ result }) =>
                result.reduce((acc, room) => {
                    acc.push({ type: TAG_TYPE_ROOM, id: room.roomId });
                    room.compartments.forEach((compartment) => {
                        acc.push({ type: TAG_TYPE_ROOM, id: compartment.roomId });
                    });

                    return acc;
                }, [])
            ),
        }),
        uploadImage: build.mutation({
            query: ({ roomId, data: formData }) => ({
                url: `/api/v1.1/rooms/${roomId}/images`,
                method: 'POST',
                data: formData,
            }),
            invalidatesTags: invalidateTags(({ queryArg }) => [
                { type: TAG_TYPE_ROOM, id: queryArg.roomId },
            ]),
        }),
        deleteImage: build.mutation({
            query: ({ roomId, imageId }) => ({
                url: `/api/v1.1/rooms/${roomId}/images/${imageId}`,
                method: 'DELETE',
            }),
            invalidatesTags: invalidateTags(({ queryArg }) => [
                { type: TAG_TYPE_ROOM, id: queryArg.roomId },
            ]),
        }),
    }),
});

export const { useGetRoomsQuery, useUploadImageMutation, useDeleteImageMutation } = roomsApi;

export default roomsApi;
