import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useGetStatisticsQuery } from '../../redux/widgets/widgets.api';
import { formatAmountInCentsRound } from '../../utils/prices';
import LinearProgress from '../../components/loaders/LinearProgress';
import Widget from '../../components/Widget/Widget';

const Widgets = () => {
    const { data: statistics } = useGetStatisticsQuery();
    if (!statistics) {
        return <LinearProgress />;
    }

    return (
        <Grid container spacing={3.5}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Widget title="Active clients" statistic={statistics.activeCompaniesCount} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Widget title="Active Campaigns" statistic={statistics.activeCampaignsCount} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Widget
                    title="Active Campaign Revenue"
                    statistic={formatAmountInCentsRound(statistics.sumRevenue)}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Widget
                    title="Total Revenue"
                    statistic={formatAmountInCentsRound(statistics.sumRevenueTotal)}
                />
            </Grid>
        </Grid>
    );
};

const Dashboard = () => (
    <Box sx={{ py: 5, px: 10 }}>
        <Typography variant="h3">Dashboard</Typography>
        <Box sx={{ mt: 4 }}>
            <Widgets />
        </Box>
    </Box>
);

export default Dashboard;
