import { Quill } from 'react-quill';

const InlineBlot = Quill.import('blots/block');

export default class ImageUploadingBlot extends InlineBlot {
    static create(src) {
        const node = super.create(src);
        if (src === true) {
            return node;
        }

        const image = document.createElement('img');
        image.setAttribute('src', src);
        node.appendChild(image);
        return node;
    }

    deleteAt(index, length) {
        super.deleteAt(index, length);
        this.cache = {};
    }

    static value(domNode) {
        const { src, custom } = domNode.dataset;
        return { src, custom };
    }
}

ImageUploadingBlot.blotName = 'imageUploading';
ImageUploadingBlot.className = 'image-uploading';
ImageUploadingBlot.tagName = 'span';

Quill.register({ [`formats/${ImageUploadingBlot.blotName}`]: ImageUploadingBlot });
