import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../helpers/axiosBaseQuery';
import { provideTags } from '../../utils/responses';

const TAG_TYPE_IDENTITY = 'IDENTITY';
const TAG_TYPE_USER = 'USER';

export const guestsApi = createApi({
    baseQuery: axiosBaseQuery(),
    reducerPath: 'guestsApi',
    tagTypes: [TAG_TYPE_IDENTITY, TAG_TYPE_USER],
    endpoints: (build) => ({
        getBranding: build.query({
            query: () => ({ url: '/api/v1.0/companies/branding' }),
            providesTags: provideTags(() => [{ type: TAG_TYPE_IDENTITY }]),
        }),
        login: build.mutation({
            query: (queryArg) => ({ url: '/api/v1.0/auth/login', method: 'POST', data: queryArg }),
        }),
        getIdentity: build.query({
            query: () => ({ url: '/api/v1.0/auth/identity' }),
            providesTags: provideTags(({ result }) => [
                { type: TAG_TYPE_USER, id: result.data.userId },
            ]),
        }),
    }),
});

export const { useLoginMutation, useLazyGetBrandingQuery, useLazyGetIdentityQuery } = guestsApi;

export default guestsApi;
