import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

const Widget = ({ title, statistic }) => (
    <Paper sx={{ py: 2, px: 3 }}>
        <Grid container spacing={1} direction="column">
            <Grid item>
                <Typography variant="subtitle1">{title}</Typography>
            </Grid>
            <Grid item>
                <Typography sx={{ fontSize: 36, fontWeight: 500 }}>{statistic}</Typography>
            </Grid>
        </Grid>
    </Paper>
);

export default Widget;
