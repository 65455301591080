import { Quill } from 'react-quill';

const QuillImageBlot = Quill.import('formats/image');

export default class ImageBlot extends QuillImageBlot {
    static create(src) {
        const node = super.create(src);
        if (src === true) {
            return node;
        }

        node.style.maxWidth = '100%';
        return node;
    }
}

Quill.register({ [`formats/${ImageBlot.blotName}`]: ImageBlot }, true);
