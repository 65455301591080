import React, { useEffect } from 'react';
import styled from 'styled-components';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';

import useImageReader from '../../../hooks/useImageReader';
import { useUploadImageMutation } from '../../../redux/rooms/rooms.api';

const RoomImageUpload = ({ roomId, className, file, type, onLoaded, height, width }) => {
    const [uploadImage, { isSuccess, isError }] = useUploadImageMutation();
    const [image] = useImageReader(file);
    useEffect(() => {
        if (!file) {
            return;
        }

        const formData = new FormData();
        formData.append('roomId', roomId);
        formData.append('image', file);
        formData.append('type', type);
        uploadImage({ roomId, data: formData });
    }, [file]);

    useEffect(() => {
        if (isSuccess || isError) {
            onLoaded(file);
        }
    }, [isSuccess, isError]);

    return (
        <Card className={className} sx={{ position: 'relative', height, width, borderRadius: 0 }}>
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                }}
            >
                <CircularProgress />
            </Box>
            {image && <CardMedia component="img" height={height} image={image} />}
        </Card>
    );
};

export default styled(RoomImageUpload)`
    opacity: 0.75;
`;
