import React from 'react';
import styled from 'styled-components';
import MuiTextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const PriceField = ({
    formik,
    required,
    disabled,
    name,
    label,
    placeholder,
    step = '0.01',
    type = 'number',
    InputProps = { startAdornment: <InputAdornment position="start">$</InputAdornment> },
}) => {
    return (
        <StyledTextField
            fullWidth
            size="small"
            type={type}
            name={name}
            disabled={disabled}
            required={required}
            error={formik.errors[name] && formik.touched[name]}
            helperText={formik.touched[name] && formik.errors[name]}
            onChange={formik.handleChange}
            value={formik.values[name]}
            label={label}
            placeholder={placeholder}
            InputLabelProps={{ shrink: true }}
            inputProps={{ step }}
            InputProps={InputProps}
        />
    );
};

const StyledTextField = styled(MuiTextField)`
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
        color: var(--color-text) !important;
        -webkit-box-shadow: 0 0 0 40px white inset;
    }
`;

export default PriceField;
