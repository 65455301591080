import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { useGetEmailTemplatesQuery } from '../../../../redux/emailTemplates/emailTemplates.api';
import { DeliveryStage } from '../../../../utils/emailTemplates';
import { pluralize } from '../../../../utils/string';
import LinearProgress from '../../../../components/loaders/LinearProgress';
import NavLink from '../../../../components/NavLink/NavLink';

import styles from './EmailTemplatesList.module.scss';

const getDeliveryStageTitle = ({ deliveryStage, daysDelay }) => {
    if (deliveryStage === DeliveryStage.DaysAfterBeginningOfCampaign) {
        return `${pluralize(daysDelay, 'day')} after beginning of campaign`;
    }

    return deliveryStage;
};

const getModifiedSubTitle = ({ name }) => {
    return name || '(Empty)';
};

const EmailTemplatesListItem = ({ emailTemplate }) => {
    return (
        <NavLink to={`/email-templates/${emailTemplate.emailTemplateId}`}>
            <Paper className={styles.EmailTemplatesListItem} sx={{ p: 2, my: 1 }}>
                <Typography variant="subtitle1" paragraph>
                    {getDeliveryStageTitle(emailTemplate)}
                </Typography>
                <Typography color="textSecondary" variant="subtitle2" paragraph>
                    {getModifiedSubTitle(emailTemplate)}
                </Typography>
            </Paper>
        </NavLink>
    );
};

const EmailTemplatesList = () => {
    const { isLoading, data: emailTemplates } = useGetEmailTemplatesQuery();
    if (isLoading) {
        return <LinearProgress />;
    }

    return (
        <>
            {emailTemplates.map((emailTemplate) => (
                <EmailTemplatesListItem
                    key={emailTemplate.emailTemplateId}
                    emailTemplate={emailTemplate}
                />
            ))}
        </>
    );
};

export default EmailTemplatesList;
