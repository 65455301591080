import React from 'react';
import MuiLinearProgress from '@mui/material/LinearProgress';

export const LinearProgress = ({ position = 'fixed' }) => {
    return (
        <MuiLinearProgress
            variant="indeterminate"
            sx={{ position, top: 0, left: 0, right: 0 }}
        />
    );
};

export default LinearProgress;
