import React, { useMemo } from 'react';
import MuiPagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

import { ReactComponent as ChevronLeftIcon } from '../../assets/svg/ChevronLeft.svg';
import { ReactComponent as ChevronRightIcon } from '../../assets/svg/ChevronRight.svg';

const Pagination = ({ onChange, total = 0, offset = 0, limit = 10 }) => {
    const count = useMemo(() => {
        return Math.ceil(total / limit);
    }, [total, limit]);

    const page = useMemo(() => {
        return 1 + Math.ceil(offset / limit);
    }, [offset, limit]);

    const handleChange = (event, page) => {
        const nextOffset = limit * (page - 1);
        if (nextOffset === offset) {
            return;
        }

        onChange({ total, limit, offset: nextOffset });
    };

    return (
        <MuiPagination
            count={count}
            page={page}
            variant="outlined"
            color="primary"
            onChange={handleChange}
            renderItem={(params) => (
                <PaginationItem
                    {...params}
                    components={{ next: ChevronRightIcon, previous: ChevronLeftIcon }}
                />
            )}
        />
    );
};

export default Pagination;
