import { useParams } from 'react-router-dom';

import MainContainer from '../../components/layouts/MainContainer/MainContainer';

import EmailTemplateForm from './components/EmailTemplateForm/EmailTemplateForm';

export const EmailTemplate = () => {
    const params = useParams();
    return (
        <MainContainer>
            <EmailTemplateForm emailTemplateId={params.emailTemplateId} />
        </MainContainer>
    );
};

export default EmailTemplate;
