import React, { useCallback, useState, useEffect, useContext, useMemo } from 'react';
import styled from 'styled-components';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';

import { useUpdateCompanyBuildingMutation } from '../../../redux/companies/companies.api';
import useDebounce from '../../../hooks/useDebounce';
import LinearProgress from '../../../components/loaders/LinearProgress';

import StatusLabel from './StatusLabel';
import ClientContext from './ClientContext';

const BuildingStatus = {
    Draft: 'DRAFT',
};

const CostTier = {
    Low: 300,
    Mid: 700,
};

const calculateCost = (unitsCount, company) => {
    if (unitsCount < CostTier.Low) {
        return company.unitCostLowTier;
    }

    if (unitsCount < CostTier.Mid) {
        return company.unitCostMidTier;
    }

    return company.unitCostHighTier;
};

const BuildingRow = ({ building, onChangeDesignServices, onChangeIsInvoiced }) => {
    const company = useContext(ClientContext);
    const [designServices, setDesignServices] = useState(building.designServices);
    const [isInvoiced, setIsInvoiced] = useState(building.isInvoiced);
    const handleChangeDesign = (event) => setDesignServices(event.target.checked);
    const handleChangeInvoiced = (event) => setIsInvoiced(event.target.checked);

    const costPerUnit = useMemo(() => {
        if (building.costPerUnit) {
            return building.costPerUnit;
        }

        return calculateCost(building.unitsCount, company);
    }, [building, company]);

    const designServicesDebounced = useDebounce(designServices, 300);
    useEffect(() => {
        if (
            typeof designServicesDebounced === 'undefined' ||
            designServicesDebounced === building.designServices
        ) {
            return;
        }

        onChangeDesignServices(building, designServicesDebounced);
    }, [building, designServicesDebounced]);

    const isInvoicedDebounced = useDebounce(isInvoiced, 300);
    useEffect(() => {
        if (
            typeof isInvoicedDebounced === 'undefined' ||
            isInvoicedDebounced === building.isInvoiced
        ) {
            return;
        }

        onChangeIsInvoiced(building, isInvoicedDebounced);
    }, [building, isInvoicedDebounced]);

    return (
        <TableRow>
            <TableCell>
                <Typography
                    title={building.name}
                    sx={{
                        whiteSpace: 'nowrap',
                        maxWidth: 150,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {building.name}
                </Typography>
            </TableCell>
            <TableCell sx={{ textAlign: 'center' }}>{building.unitsCount}</TableCell>
            <TableCell sx={{ textAlign: 'center' }}>${costPerUnit}</TableCell>
            <TableCell sx={{ textAlign: 'center' }}>
                {building.totalRevenue ? `$${building.totalRevenue}` : '-'}
            </TableCell>
            <TableCell padding="checkbox" sx={{ textAlign: 'center' }}>
                <Checkbox color="primary" onChange={handleChangeDesign} checked={designServices} />
            </TableCell>
            <TableCell padding="checkbox" sx={{ textAlign: 'center' }}>
                <Checkbox
                    color="primary"
                    onChange={handleChangeInvoiced}
                    checked={isInvoiced}
                    disabled={building.status === BuildingStatus.Draft}
                />
            </TableCell>
            <TableCell>
                <StatusLabel building={building} />
            </TableCell>
        </TableRow>
    );
};

const BuildingsTable = ({ buildings }) => {
    const [updateCompanyBuilding, { isLoading }] = useUpdateCompanyBuildingMutation();
    const handleChangeDesign = useCallback(
        (building, designServices) => {
            updateCompanyBuilding({
                companyId: building.companyId,
                buildingId: building.buildingId,
                designServices,
            });
        },
        [updateCompanyBuilding]
    );

    const handleChangeInvoiced = useCallback(
        (building, isInvoiced) => {
            updateCompanyBuilding({
                companyId: building.companyId,
                buildingId: building.buildingId,
                isInvoiced,
            });
        },
        [updateCompanyBuilding]
    );

    if (!buildings) {
        return null;
    }

    return (
        <>
            {isLoading && <LinearProgress />}
            <StyledTableContainer sx={{ mt: 3 }}>
                <Table size="small" sx={{ borderCollapse: 'separate', borderSpacing: '0 16px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ whiteSpace: 'nowrap' }}>Building Name</TableCell>
                            <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                                Number of Units
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                                Cost per Unit
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                                Total Revenue
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center', whiteSpace: 'nowrap' }}>
                                Design Services
                            </TableCell>
                            <TableCell sx={{ textAlign: 'center' }}>Invoiced</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {buildings.map((building) => (
                            <BuildingRow
                                key={building.buildingId}
                                building={building}
                                onChangeDesignServices={handleChangeDesign}
                                onChangeIsInvoiced={handleChangeInvoiced}
                            />
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>
        </>
    );
};

const StyledTableContainer = styled(TableContainer)({
    '& .MuiTableCell-root': {
        height: 50,
    },
});

export default BuildingsTable;
