import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MainContainer from '../../components/layouts/MainContainer/MainContainer';

import EmailTemplatesList from './components/EmailTemplatesList/EmailTemplatesList';

const EmailTemplates = () => {
    return (
        <MainContainer>
            <Grid container direction="column" spacing={3}>
                <Grid item>
                    <Typography variant="h1" style={{ textTransform: 'capitalize' }}>
                        Campaign emails
                    </Typography>
                </Grid>
                <Grid item>
                    <EmailTemplatesList />
                </Grid>
            </Grid>
        </MainContainer>
    );
};

export default EmailTemplates;
