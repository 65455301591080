import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import PrimaryButton from '../buttons/PrimaryButton';

import { ReactComponent as CheckIcon } from '../../assets/svg/Check.svg';

const SaveSuccessfulModal = ({ onClose, open, subtitle, title = 'Saved successfully' }) => {
    return (
        <Dialog disableEscapeKeyDown onClose={onClose} open={open}>
            <DialogContent sx={{ minWidth: 420, pt: 5, pb: 4, px: 3 }}>
                <Grid
                    container
                    spacing={2}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item>
                        <SvgIcon
                            component={CheckIcon}
                            viewBox="0 0 57 57"
                            sx={{ width: 56, height: 56 }}
                        />
                    </Grid>
                    <Grid item sx={{ textAlign: 'center' }}>
                        <Typography variant="h2">{title}</Typography>
                        {subtitle && (
                            <Typography variant="body2" sx={{ mt: 2 }}>
                                {subtitle}
                            </Typography>
                        )}
                    </Grid>
                    <Grid item sx={{ mt: 1 }}>
                        <PrimaryButton sx={{ py: 1, px: 5 }} onClick={onClose}>
                            Ok
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default SaveSuccessfulModal;
