import React, { useCallback, useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Prompt } from 'react-router-dom';
import * as yup from 'yup';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import SvgIcon from '@mui/material/SvgIcon';

import { DeliveryStage } from '../../../../utils/emailTemplates';
import PrimaryButton from '../../../../components/buttons/PrimaryButton';
import PreviewEditor from '../../../../components/ContentEditor/PreviewEditor';
import SaveSuccessfulModal from '../../../../components/modals/SaveSuccessfulModal';
import NumberControl from '../../../../components/inputs/NumberControl';
import LinearProgress from '../../../../components/loaders/LinearProgress';
import {
    useGetEmailTemplateQuery,
    useUpdateEmailTemplateMutation,
    useUploadEmailTemplateImageMutation,
} from '../../../../redux/emailTemplates/emailTemplates.api';

import { ReactComponent as InfoIcon } from '../../../../assets/svg/Info.svg';

import styles from './EmailTemplateForm.module.scss';

const validationSchema = yup.object().shape({
    name: yup.string().trim().max(60),
    subject: yup.string().trim().max(100).required('Subject is required'),
    template: yup.string().trim().max(20000),
    source: yup.string().trim().max(100000),
    deliveryStage: yup.string().oneOf(Object.values(DeliveryStage)).required(),
    daysDelay: yup.number().min(1).max(365).nullable(),
});

const getEmailTemplateValues = (emailTemplate = {}) => ({
    name: emailTemplate.name || '',
    subject: emailTemplate.subject || '',
    template: emailTemplate.template || '',
    source: emailTemplate.source || '',
    deliveryStage: emailTemplate.deliveryStage || '',
    daysDelay: emailTemplate.daysDelay || null,
});

const getInfoByDeliveryStage = (deliveryStage) => {
    switch (deliveryStage) {
        case DeliveryStage.DaysAfterBeginningOfCampaign: {
            return 'This email will be sent only if the client has not yet registered.';
        }

        case 'Thank you for your selection': {
            return 'Emails will be automatically sent to clients who have successfully submitted their selections.';
        }

        default: {
            return 'Initial emails sent out at the beginning of the campaign cannot be adjusted.';
        }
    }
};

const getValidationErrorMessage = ([error]) => {
    const { param, value, msg } = error;
    if (msg !== 'Invalid value') {
        return msg;
    }

    let message = `${param} has invalid value`;
    if (String(value).length < 20) {
        message += ` '${value}'`;
    }

    return message;
};

const getErrorMessage = (error) => {
    if (!error) {
        return null;
    }

    if (!error.data) {
        return error.message;
    }

    // validation
    if (error.data.errors) {
        return getValidationErrorMessage(error.data.errors);
    }

    // server error message
    return error.data.error;
};

const EmailTemplateForm = ({ emailTemplateId }) => {
    const { enqueueSnackbar } = useSnackbar();
    const { isLoading, data: emailTemplate } = useGetEmailTemplateQuery({ emailTemplateId });
    const [
        updateEmailTemplate,
        { error: errorUpdate, isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate },
    ] = useUpdateEmailTemplateMutation();

    const [uploadEmailTemplateImage, { error: errorUpload }] =
        useUploadEmailTemplateImageMutation();

    const uploadImage = useCallback(async (file) => {
        const formData = new FormData();
        formData.append('image', file);
        formData.append('type', 'EMAIL_TEMPLATE_IMG');
        const res = await uploadEmailTemplateImage({ emailTemplateId, formData });
        return res.data;
    }, []);

    const formik = useFormik({
        validationSchema,
        initialValues: getEmailTemplateValues(emailTemplate),
        onSubmit: (values) => {
            updateEmailTemplate({ emailTemplateId: emailTemplate.emailTemplateId, ...values });
        },
    });

    const handleChangeTemplate = useCallback(
        (template) => {
            formik.setFieldValue('template', template);
        },
        [formik]
    );

    const handleChangeDaysDelay = useCallback(
        (daysDelay) => {
            formik.setFieldValue('daysDelay', daysDelay);
        },
        [formik]
    );

    const [openSuccessfulModal, setOpenSuccessfulModal] = useState(false);
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }

        setOpenSuccessfulModal(false);
    };

    useEffect(() => {
        if (emailTemplate) {
            formik.resetForm({ values: getEmailTemplateValues(emailTemplate) });
        }
    }, [emailTemplate]);

    useEffect(() => {
        if (isSuccessUpdate) {
            setOpenSuccessfulModal(true);
        }
    }, [isSuccessUpdate]);

    useEffect(() => {
        [errorUpdate, errorUpload].forEach((error) => {
            if (error) {
                enqueueSnackbar(getErrorMessage(error), { variant: 'error' });
            }
        });
    }, [errorUpdate, errorUpload]);

    if (isLoading) {
        return <LinearProgress />;
    }

    return (
        <>
            {isLoadingUpdate && <LinearProgress />}
            <form onSubmit={formik.handleSubmit}>
                <Prompt
                    message="You have unsaved changes, are you sure you want to leave?"
                    when={formik.dirty}
                />
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <Typography variant="h1" style={{ textTransform: 'capitalize' }}>
                                    {formik.values.name || 'Untitled'}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <PrimaryButton sx={{ py: 1, px: 5 }} type="submit">
                            Save template
                        </PrimaryButton>
                    </Grid>
                </Grid>
                <Grid container spacing={4}>
                    <Grid item md={4} xs={12}>
                        <Paper elevation={0} sx={{ my: 3, p: 4 }}>
                            <TextField
                                fullWidth
                                name="name"
                                error={formik.errors.name && formik.touched.name}
                                helperText={formik.touched.name && formik.errors.name}
                                onChange={formik.handleChange}
                                value={formik.values.name}
                                InputLabelProps={{ shrink: true }}
                                label="Template name"
                                placeholder="Enter template name"
                                size="small"
                            />
                        </Paper>
                        <Paper elevation={0} sx={{ my: 3, p: 4 }}>
                            <TextField
                                fullWidth
                                name="subject"
                                error={formik.errors.subject && formik.touched.subject}
                                helperText={formik.touched.subject && formik.errors.subject}
                                onChange={formik.handleChange}
                                value={formik.values.subject}
                                InputLabelProps={{ shrink: true }}
                                label="Subject"
                                placeholder="Enter subject line"
                                size="small"
                            />
                        </Paper>
                        <Paper elevation={0} sx={{ my: 3, p: 4 }}>
                            {formik.values.deliveryStage ===
                            DeliveryStage.DaysAfterBeginningOfCampaign ? (
                                <>
                                    <Grid container direction="row" alignItems="center">
                                        <Grid item>
                                            <NumberControl
                                                name="daysDelay"
                                                error={
                                                    formik.errors.daysDelay &&
                                                    formik.touched.daysDelay
                                                }
                                                helperText={
                                                    formik.touched.daysDelay &&
                                                    formik.errors.daysDelay
                                                }
                                                onChange={handleChangeDaysDelay}
                                                value={formik.values.daysDelay}
                                                label="Delivery stage"
                                                min={1}
                                                max={365}
                                            />
                                        </Grid>
                                        <Grid item xs>
                                            <Typography component="span">
                                                {DeliveryStage.DaysAfterBeginningOfCampaign}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <FormHelperText sx={{ mt: 2 }}>
                                        <SvgIcon
                                            className={styles.TextHelper}
                                            component={InfoIcon}
                                            viewBox="0 0 12 12"
                                            sx={{ width: 12, height: 12, mr: 1 }}
                                        />
                                        {getInfoByDeliveryStage(formik.values.deliveryStage)}
                                    </FormHelperText>
                                </>
                            ) : (
                                <>
                                    <TextField
                                        fullWidth
                                        disabled
                                        name="deliveryStage"
                                        error={
                                            formik.errors.deliveryStage &&
                                            formik.touched.deliveryStage
                                        }
                                        helperText={
                                            formik.touched.deliveryStage &&
                                            formik.errors.deliveryStage
                                        }
                                        onChange={formik.handleChange}
                                        value={formik.values.deliveryStage}
                                        InputProps={{ className: styles.disabledInput }}
                                        InputLabelProps={{ shrink: true }}
                                        label="Delivery stage"
                                        size="small"
                                    />
                                    <FormHelperText sx={{ mt: 2 }}>
                                        <SvgIcon
                                            className={styles.TextHelper}
                                            component={InfoIcon}
                                            viewBox="0 0 12 12"
                                            sx={{ width: 12, height: 12, mr: 1 }}
                                        />
                                        {getInfoByDeliveryStage(formik.values.deliveryStage)}
                                    </FormHelperText>
                                </>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item md={8} xs={12}>
                        <Paper sx={{ my: 3, p: 4 }}>
                            <PreviewEditor
                                key={emailTemplate.template}
                                onChange={handleChangeTemplate}
                                defaultContent={emailTemplate.template}
                                uploadImage={uploadImage}
                                placeholder="Start adding images/links and typing out the body of your email here..."
                                helperText={formik.touched.template && formik.errors.template}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            </form>
            <SaveSuccessfulModal
                onClose={handleClose}
                open={openSuccessfulModal}
                subtitle="Visit your settings at any time to make changes."
            />
        </>
    );
};

export default EmailTemplateForm;
