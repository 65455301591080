import { Redirect, Route, Switch } from 'react-router-dom';

import AccountHeader from '../components/AccountHeader/AccountHeader';

import Dashboard from './Dashboard/Dashboard';
import ClientsPage from './Clients/ClientsPage';
import EmailTemplates from './EmailTemplates/EmailTemplates';
import EmailTemplate from './EmailTemplates/EmailTemplate';
import Taxes from './Taxes/Taxes';
import Options from './Options/Options';

import ClientPage from './Client/routes';

const AccountRoutes = () => {
    return (
        <>
            <AccountHeader />
            <Switch>
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/clients/:companyId" component={ClientPage} />
                <Route path="/clients" component={ClientsPage} />
                <Route path="/email-templates/:emailTemplateId" component={EmailTemplate} />
                <Route path="/email-templates" component={EmailTemplates} />
                <Route path="/taxes" component={Taxes} />
                <Route path="/options" component={Options} />
                <Redirect to="/dashboard" />
            </Switch>
        </>
    );
};

export default AccountRoutes;
