import React, { useCallback } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as ArrowLeftIcon } from '../../assets/svg/ArrowLeft.svg';
import { ReactComponent as ArrowRightIcon } from '../../assets/svg/ArrowRight.svg';

const NumberControl = ({ name, label, value, error, helperText, onChange, min = 0, max = 100 }) => {
    const handleClick = useCallback(
        (event) => {
            const change = event.currentTarget.value === 'up' ? 1 : -1;
            const prevValue = parseInt(value, 10) || 0;
            const nextValue = Math.min(Math.max(min, prevValue + change), max);
            onChange(nextValue);
        },
        [onChange, value]
    );

    const handleChange = useCallback(
        (event) => {
            const prevValue = parseInt(event.target.value, 10);
            if (Number.isNaN(prevValue)) {
                return;
            }

            const nextValue = Math.min(Math.max(min, prevValue), max);
            onChange(nextValue);
        },
        [onChange]
    );

    return (
        <TextField
            name={name}
            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start" sx={{ position: 'absolute', left: -20 }}>
                        <IconButton value="down" edge="start" onClick={handleClick}>
                            <SvgIcon
                                component={ArrowLeftIcon}
                                viewBox="0 0 7 9"
                                sx={{ width: 16, height: 16 }}
                            />
                        </IconButton>
                    </InputAdornment>
                ),
                endAdornment: (
                    <InputAdornment position="end" sx={{ position: 'absolute', right: -20 }}>
                        <IconButton edge="end" value="up" onClick={handleClick}>
                            <SvgIcon
                                component={ArrowRightIcon}
                                viewBox="0 0 7 9"
                                sx={{ width: 16, height: 16 }}
                            />
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            error={error}
            helperText={helperText}
            value={value}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            label={label}
            size="small"
            sx={{ ml: 3, mr: 5, maxWidth: 108 }}
        />
    );
};

export default React.memo(NumberControl);
