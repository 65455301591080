import React, { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const navs = [
    {
        label: 'Dashboard',
        to: '/dashboard',
    },
    {
        label: 'Clients',
        to: '/clients',
    },
    {
        label: 'Email templates',
        to: '/email-templates',
    },
    {
        label: 'Taxes',
        to: '/taxes',
    },
    {
        label: 'Options',
        to: '/options',
    },
];

export const MainNavBar = () => {
    const location = useLocation();
    const activeTabIndex = useMemo(() => {
        const index = navs.findIndex((nav) => nav.to === location.pathname);
        return index === -1 ? false : index;
    }, [location]);

    return (
        <Grid container direction="row" alignContent="center" sx={{ pl: 3 }}>
            <Grid item>
                <Tabs
                    value={activeTabIndex}
                    TabIndicatorProps={{
                        children: <span className="MuiTabs-indicatorSpan" />,
                    }}
                >
                    {navs.map((nav) => (
                        <Tab
                            key={nav.label}
                            component={NavLink}
                            label={nav.label}
                            to={nav.to}
                            style={{ textTransform: 'initial' }}
                        />
                    ))}
                </Tabs>
            </Grid>
        </Grid>
    );
};

export default MainNavBar;
