import React from 'react';
import { motion } from 'framer-motion';

const MotionLayout = ({
    children,
    transition,
    initial = { opacity: 0 },
    animate = { opacity: 1 },
    exit = { opacity: 0 },
}) => {
    return (
        <motion.div layout initial={initial} animate={animate} exit={exit} transition={transition}>
            {children}
        </motion.div>
    );
};

export default MotionLayout;
