import { DateTime } from 'luxon';

export const toRelative = (date) => {
    if (!date) {
        return null;
    }

    return DateTime.fromISO(date).toRelative({ locale: 'en-CA' });
};

export const DATE_FORMAT = 'LLL dd, yyyy hh:mm a';
export const formatISODate = (isoString, format = DATE_FORMAT) => {
    return DateTime.fromISO(isoString).toFormat(format);
};

export const DATE_ONLY_FORMAT = 'LLLL dd, yyyy';
export const formatSeconds = (seconds, format = DATE_ONLY_FORMAT) => {
    return DateTime.fromSeconds(seconds).toFormat(format);
};
