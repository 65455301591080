import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

import { ReactComponent as MoreIcon } from '../../assets/svg/more.svg';

export const PrimaryPopoverButton = ({ onClick, popoverRef }) => {
    return (
        <Button
            color="primary"
            variant="outlined"
            ref={popoverRef}
            onClick={onClick}
            sx={{ minWidth: 40, width: 40, height: 40, borderRadius: 20 }}
        >
            <SvgIcon
                className="svg-icon"
                component={MoreIcon}
                viewBox="0 0 18 4"
                sx={{ width: 18, height: 18 }}
            />
        </Button>
    );
};

export const PopoverButton = ({ onClick, popoverRef, color = 'default' }) => {
    if (color === 'primary') {
        return <PrimaryPopoverButton onClick={onClick} popoverRef={popoverRef} />;
    }

    return (
        <IconButton ref={popoverRef} onClick={onClick}>
            <SvgIcon component={MoreIcon} viewBox="0 0 18 4" sx={{ width: 18, height: 18 }} />
        </IconButton>
    );
};

export default PopoverButton;
