/* eslint-disable  react-hooks/rules-of-hooks */
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import { axiosInstance } from '../helpers/axiosInstance';
import { useAuth } from '../helpers/axiosMiddleware';

import commonSlice from './common/commonSlice';
import guestsApi from './guests/guests.api';
import emailTemplatesApi from './emailTemplates/emailTemplates.api';
import companiesApi from './companies/companies.api';
import companyTaxesApi from './companyTaxes/companyTaxes.api';
import roomsApi from './rooms/rooms.api';
import widgetsApi from './widgets/widgets.api';

export const reducer = combineReducers({
    common: commonSlice,
    [guestsApi.reducerPath]: guestsApi.reducer,
    [emailTemplatesApi.reducerPath]: emailTemplatesApi.reducer,
    [companiesApi.reducerPath]: companiesApi.reducer,
    [companyTaxesApi.reducerPath]: companyTaxesApi.reducer,
    [roomsApi.reducerPath]: roomsApi.reducer,
    [widgetsApi.reducerPath]: widgetsApi.reducer,
});

const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            guestsApi.middleware,
            emailTemplatesApi.middleware,
            companiesApi.middleware,
            companyTaxesApi.middleware,
            roomsApi.middleware,
            widgetsApi.middleware
        ),
});

useAuth(axiosInstance, store);

export default store;
