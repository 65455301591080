import React, { useEffect, useMemo } from 'react';
import { Prompt } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import styled from 'styled-components';
import { useSnackbar } from 'notistack';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useUpdateCompanyMutation } from '../../../redux/companies/companies.api';
import { formatSeconds } from '../../../utils/date';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import TextField from '../../../components/inputs/TextField';
import PriceField from '../../../components/inputs/PriceField';
import LinearProgress from '../../../components/loaders/LinearProgress';

const validationSchema = yup.object().shape({
    unitCostLowTier: yup.number().min(0).required(),
    unitCostMidTier: yup.number().min(0).required(),
    unitCostHighTier: yup.number().min(0).required(),
});

const getCompanyValues = ({ unitCostLowTier, unitCostMidTier, unitCostHighTier }) => ({
    unitCostLowTier: unitCostLowTier || 0,
    unitCostMidTier: unitCostMidTier || 0,
    unitCostHighTier: unitCostHighTier || 0,
});

const getCompanyData = ({ unitCostLowTier, unitCostMidTier, unitCostHighTier }) => ({
    unitCostLowTier,
    unitCostMidTier,
    unitCostHighTier,
});

const SubscriptionStatus = {
    Active: 'active',
};

const CompanyPricing = ({ company, subscription }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [
        updateCompany,
        { data, isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate, isError: isErrorUpdate },
    ] = useUpdateCompanyMutation();

    const companyId = company.companyId;
    const formik = useFormik({
        validationSchema,
        initialValues: useMemo(() => getCompanyValues(company), []),
        onSubmit: (values) => {
            const data = getCompanyData(values);
            updateCompany({ companyId, ...data });
        },
    });

    const subscriptionFormik = useFormik({
        initialValues: useMemo(() => ({ interval: '', currentPeriodEnd: '' })),
    });

    useEffect(() => {
        if (!subscription || subscription.status !== SubscriptionStatus.Active) {
            return;
        }

        subscriptionFormik.setFieldValue('interval', subscription.plan.interval);
        subscriptionFormik.setFieldValue(
            'currentPeriodEnd',
            formatSeconds(subscription.current_period_end)
        );
    }, [subscription]);

    useEffect(() => {
        if (data) {
            formik.resetForm({ values: getCompanyValues(data) });
        }
    }, [isSuccessUpdate]);

    useEffect(() => {
        if (isErrorUpdate) {
            enqueueSnackbar('Failed update company', { variant: 'error' });
        }
    }, [isErrorUpdate]);

    return (
        <StyledBox>
            {isLoadingUpdate && <LinearProgress />}
            <Prompt
                message="You have unsaved changes, are you sure you want to leave?"
                when={formik.dirty}
            />
            <form onSubmit={formik.handleSubmit}>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    Company Pricing
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={4}>
                                <PriceField
                                    formik={formik}
                                    name="unitCostLowTier"
                                    label="Units: 1 - 299"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <PriceField
                                    formik={formik}
                                    name="unitCostMidTier"
                                    label="Units: 300 - 699"
                                />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <PriceField
                                    formik={formik}
                                    name="unitCostHighTier"
                                    label="Units: 700 & Up"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" sx={{ mb: 2 }}>
                            Hosting
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    readOnly
                                    formik={subscriptionFormik}
                                    name="interval"
                                    label="Hosting Plan"
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    readOnly
                                    formik={subscriptionFormik}
                                    name="currentPeriodEnd"
                                    label="Next Billing Date"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: 'right' }}>
                        <PrimaryButton sx={{ py: 1, px: 5 }} type="submit">
                            SAVE
                        </PrimaryButton>
                    </Grid>
                </Grid>
            </form>
        </StyledBox>
    );
};

const StyledBox = styled(Box)`
    padding: 24px;
    border-radius: 12px;
    background-color: #ffffff;
    box-shadow: var(--box-shadow);
`;

export default CompanyPricing;
