import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

const PrimaryButton = styled(Button)(() => ({
    borderRadius: 100,
    textStyle: 'uppercase',
    color: 'white',
    background: 'linear-gradient(90deg, var(--company-primary-color-dark) 0%, var(--company-primary-color-light ) 100%)',
}));

export default PrimaryButton;
