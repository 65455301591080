import { createSlice } from '@reduxjs/toolkit';

import AuthService from '../../services/AuthService';

const isEndpoint = (action, endpointName) => action.meta.arg.endpointName === endpointName;
const getBranding = ({ company }) => ({
    images: company.images,
    textColor: company.textColor,
    primaryColor: company.primaryColor,
});

const initialState = {
    branding: null,
    identity: null,
    isAuth: AuthService.isAuthenticated(),
};

const commonSlice = createSlice({
    initialState,
    name: 'common',
    reducers: {
        resetAuth(state) {
            state.isAuth = false;
            state.identity = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase('guestsApi/executeQuery/fulfilled', (state, action) => {
                if (isEndpoint(action, 'getBranding')) {
                    state.branding = action.payload;
                }

                if (isEndpoint(action, 'getIdentity')) {
                    state.identity = action.payload.data;
                    state.branding = getBranding(action.payload.data);
                }
            })
            .addCase('guestsApi/executeMutation/fulfilled', (state, action) => {
                if (
                    (isEndpoint(action, 'login') || isEndpoint(action, 'verifyAndRegister')) &&
                    action.payload.accessToken
                ) {
                    AuthService.setToken(action.payload.accessToken);
                    state.isAuth = AuthService.isAuthenticated();
                }
            });
    },
});

export const { resetAuth } = commonSlice.actions;

export default commonSlice.reducer;
