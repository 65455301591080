import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as LogoIcon } from '../../assets/svg/decoroLogo.svg';

const Logo = () => {
    return (
        <Grid container spacing={1} alignItems="center" flexWrap="nowrap">
            <Grid item>
                <SvgIcon
                    component={LogoIcon}
                    viewBox="0 0 37 38"
                    sx={{ verticalAlign: 'middle', width: 38, height: 38 }}
                />
            </Grid>
            <Grid item>
                <Typography component="span" sx={{ fontSize: 28, fontWeight: 500 }}>
                    decoro
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Logo;
