import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import ProfileSettings from '../ProfileSettings/ProfileSettings';
import MainNavBar from '../MainNavBar/MainNavBar';
import Logo from '../Logo/Logo';
import NavLink from '../NavLink/NavLink';

const AccountHeader = () => {
    return (
        <Box sx={{ px: 10, backgroundColor: '#ffffff', boxShadow: 'var(--box-shadow)' }}>
            <Grid container alignItems="center" flexWrap="nowrap" sx={{ height: 80 }}>
                <Grid item>
                    <NavLink to="/dashboard">
                        <Logo />
                    </NavLink>
                </Grid>
                <Grid item xs>
                    <MainNavBar />
                </Grid>
                <Grid item>
                    <ProfileSettings />
                </Grid>
            </Grid>
        </Box>
    );
};

export default AccountHeader;
