import { Quill } from 'react-quill';

import './quill.shortcode.css';

const Parchment = Quill.import('parchment');

export default class ShortcodeBlot extends Parchment.Embed {
    static create(value) {
        const node = super.create(value);
        if (value === true) {
            return node;
        }

        node.textContent = value;
        node.contentEditable = 'false';
        return node;
    }

    static value(node) {
        // Only return the text of the first child
        // node (ie the text node), otherwise the
        // value includes the contents of the button
        return node.textContent;
    }
}

ShortcodeBlot.blotName = 'shortcode';
ShortcodeBlot.tagName = 'SPAN';
ShortcodeBlot.className = 'ql-shortcode';

Quill.register(ShortcodeBlot);
