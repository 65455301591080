import React from 'react';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';

import ErrorButton from '../buttons/ErrorButton';
import OutlinedPrimaryButton from '../buttons/OutlinedPrimaryButton';

import { ReactComponent as CloseIcon } from '../../assets/svg/close.svg';

const ConfirmActionModal = ({
    title,
    onClose,
    onConfirm,
    open,
    action = 'Delete',
    cancel = 'Cancel',
}) => {
    const handleClose = (event, reason) => {
        if (reason === 'backdropClick') {
            return;
        }

        onClose(event, reason);
    };

    return (
        <Dialog disableEscapeKeyDown onClose={handleClose} open={open}>
            <DialogTitle id="confirmation-title" sx={{ minHeight: 48 }}>
                <IconButton onClick={onClose} sx={{ position: 'absolute', right: 20, top: 24 }}>
                    <SvgIcon
                        component={CloseIcon}
                        viewBox="0 0 20 20"
                        sx={{ width: 24, height: 24 }}
                    />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{ minWidth: { xs: 450, sm: 590 }, my: 4, px: 8 }}>
                <Grid container spacing={5} direction="column" alignItems="center">
                    <Grid item>
                        <Typography variant="h3">{title}</Typography>
                    </Grid>
                    <Grid item>
                        <ErrorButton
                            disableElevation
                            variant="contained"
                            color="error"
                            onClick={onConfirm}
                            sx={{ minWidth: 135, mr: 3 }}
                        >
                            {action}
                        </ErrorButton>
                        <OutlinedPrimaryButton
                            autoFocus
                            onClick={onClose}
                            variant="outlined"
                            sx={{ minWidth: 135 }}
                        >
                            {cancel}
                        </OutlinedPrimaryButton>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default ConfirmActionModal;
