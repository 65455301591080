import { useEffect, useState } from 'react';

export const useDebounce = (nextState, delay = 500) => {
    const [value, setValue] = useState(nextState);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setValue(nextState);
        }, delay);
        return () => clearTimeout(timeout);
    }, [nextState]);
    return value;
};

export default useDebounce;
