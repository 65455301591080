import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import InputAdornment from '@mui/material/InputAdornment';
import SvgIcon from '@mui/material/SvgIcon';
import IconButton from '@mui/material/IconButton';

import { useLoginMutation } from '../../redux/guests/guests.api';
import LinearProgress from '../../components/loaders/LinearProgress';
import PrimaryButton from '../../components/buttons/PrimaryButton';

import { ReactComponent as ShowIcon } from '../../assets/svg/showPasswordIcon.svg';
import { ReactComponent as HideIcon } from '../../assets/svg/hidePasswordIcon.svg';

const validationSchema = yup.object().shape({
    email: yup.string().email().required(),
    password: yup.string().min(8).required(),
});

const Login = () => {
    const [type, setType] = useState('password');
    const { enqueueSnackbar } = useSnackbar();
    const [login, { isError, isLoading }] = useLoginMutation();
    const formik = useFormik({
        validationSchema,
        initialValues: { email: '', password: '' },
        onSubmit: (values) => {
            login({ login: values.email, password: values.password, audience: 'master' });
        },
    });

    const handleClickToggle = () => {
        setType((type) => (type === 'password' ? 'text' : 'password'));
    };

    useEffect(() => {
        if (isError) {
            enqueueSnackbar('Invalid email or password', { variant: 'error' });
        }
    }, [isError]);

    return (
        <Container>
            {isLoading && <LinearProgress />}
            <Grid
                container
                direction="column"
                sx={{ height: '100vh' }}
                justifyContent="center"
                alignItems="center"
            >
                <Grid item>
                    <StyledPaper sx={{ pt: 6, pb: 9, px: 9, minWidth: { sm: 500 } }}>
                        <Box>
                            <Typography component="h1" variant="h3" align="center">
                                Login
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 5 }}>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container spacing={4} direction="column">
                                    <Grid item>
                                        <StyledTextField
                                            fullWidth
                                            type="email"
                                            name="email"
                                            error={formik.errors.email && formik.touched.email}
                                            helperText={formik.touched.email && formik.errors.email}
                                            onChange={formik.handleChange}
                                            value={formik.values.email}
                                            InputLabelProps={{ shrink: true }}
                                            label="Email"
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <StyledTextField
                                            fullWidth
                                            type={type}
                                            name="password"
                                            error={
                                                formik.errors.password && formik.touched.password
                                            }
                                            helperText={
                                                formik.touched.password && formik.errors.password
                                            }
                                            onChange={formik.handleChange}
                                            value={formik.values.password}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton onClick={handleClickToggle}>
                                                            <SvgIcon
                                                                component={
                                                                    type === 'password'
                                                                        ? ShowIcon
                                                                        : HideIcon
                                                                }
                                                                viewBox={
                                                                    type === 'password'
                                                                        ? '0 0 19 10'
                                                                        : '0 0 16 14'
                                                                }
                                                                sx={{ width: 16, height: 16 }}
                                                            />
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            InputLabelProps={{ shrink: true }}
                                            label="Password"
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item>
                                        <Grid container justifyContent="center">
                                            <Grid item>
                                                <PrimaryButton
                                                    type="submit"
                                                    disabled={isLoading}
                                                    sx={{ py: 1, px: 5, textTransform: 'none' }}
                                                >
                                                    Log In
                                                </PrimaryButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </form>
                        </Box>
                    </StyledPaper>
                </Grid>
            </Grid>
        </Container>
    );
};

const StyledPaper = styled(Paper)`
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1), 0 3px 30px rgba(0, 0, 0, 0.1);
    border-radius: 1px;
`;

const StyledTextField = styled(TextField)`
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
        color: var(--color-text) !important;
        -webkit-box-shadow: 0 0 0 40px white inset;
    }
`;

export default Login;
