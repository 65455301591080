import React, { useState } from 'react';
import { LayoutGroup } from 'framer-motion';
import Grid from '@mui/material/Grid';

import { RoomImageType } from '../../../utils/rooms';
import DnDBox from '../../../components/dnd/DnDBox';
import MotionLayout from '../../../components/MotionLayout/MotionLayout';

import RoomImageCard from './RoomImageCard';
import RoomImageUpload from './RoomImageUpload';

import { ReactComponent as PhotoIcon } from '../../../assets/svg/Photo.svg';

const RoomImageList = ({
    roomId,
    images,
    limit = 1,
    height = 192,
    width = 268,
    type = RoomImageType.RoomImage,
    placeholder,
}) => {
    const [files, setFiles] = useState([]);
    const handleLoad = (file) => {
        setFiles((files) => [...files, file]);
    };

    const handleLoaded = (file) => {
        setFiles((files) => files.filter((f) => f !== file));
    };

    const total = images.length + files.length;
    const dimensions = { width: width + 24, height: height };
    return (
        <LayoutGroup>
            <Grid container spacing={3}>
                {images.map((image) => (
                    <Grid item key={image.imageId} sx={dimensions}>
                        <MotionLayout>
                            <RoomImageCard image={image} height={height} />
                        </MotionLayout>
                    </Grid>
                ))}
                {files.map((file) => (
                    <Grid item key={file.name} sx={dimensions}>
                        <MotionLayout>
                            <RoomImageUpload
                                roomId={roomId}
                                onLoaded={handleLoaded}
                                file={file}
                                type={type}
                                height={height}
                                width={width}
                            />
                        </MotionLayout>
                    </Grid>
                ))}
                {total < limit && (
                    <Grid item sx={dimensions}>
                        <MotionLayout transition={{ delay: 0.1 }}>
                            <DnDBox
                                multiple
                                rounded={false}
                                bordered={false}
                                onLoadFile={handleLoad}
                                maxFiles={limit - total}
                                icon={PhotoIcon}
                                viewBox="0 0 48 48"
                                width={width}
                                height={height}
                                text={placeholder}
                            />
                        </MotionLayout>
                    </Grid>
                )}
            </Grid>
        </LayoutGroup>
    );
};

export default RoomImageList;
