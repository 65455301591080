import React, { useCallback } from 'react';
import cn from 'classnames';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import FormHelperText from '@mui/material/FormHelperText';

export const DnDBox = ({
    className,
    viewBox,
    maxFiles,
    onLoadFile,
    height,
    width,
    icon: Icon,
    fontSize = 14,
    iconSize = 48,
    multiple = false,
    maxSize = 15 * 1024 * 1024,
    boxProps = { p: 2 },
    bordered = true,
    rounded = true,
    gridProps = { spacing: 1, direction: 'column' },
    iconGridProps = { mt: 2 },
    text = 'Drag and drop to upload or click to browse',
}) => {
    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach(onLoadFile);
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        multiple,
        maxFiles,
        maxSize,
        accept: 'image/*',
    });

    return (
        <Box
            className={cn(className, { active: isDragActive, rounded, bordered })}
            sx={{ height, width, p: boxProps.p }}
            {...getRootProps()}
        >
            <Grid
                container
                spacing={gridProps.spacing}
                direction={gridProps.direction}
                height="100%"
                alignItems="center"
                justifyContent="center"
                flexWrap="nowrap"
            >
                <Grid item sx={{ mt: iconGridProps.mt }}>
                    <SvgIcon
                        component={Icon}
                        viewBox={viewBox}
                        sx={{ width: iconSize, height: iconSize }}
                    />
                </Grid>
                <Grid item>
                    <input {...getInputProps()} />
                    <FormHelperText sx={{ fontSize, textAlign: 'center' }}>{text}</FormHelperText>
                </Grid>
            </Grid>
        </Box>
    );
};

export default styled(DnDBox)`
    cursor: pointer;

    &.bordered {
        border: 1px dashed #cccccc;
    }

    &.rounded {
        border-radius: 12px;
    }

    .MuiFormHelperText-root {
        color: #858585;
    }

    &.active,
    &:active {
        border-color: var(--company-primary-color);

        path {
            fill: var(--company-primary-color);
        }

        .MuiFormHelperText-root {
            color: var(--company-primary-color);
        }
    }
`;
