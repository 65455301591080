import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import {
    useGetCompanyStatisticsQuery,
    useGetCompanyBuildingsQuery,
} from '../../redux/companies/companies.api';

import { formatAmountInCentsRound } from '../../utils/prices';
import Widget from '../../components/Widget/Widget';
import LinearProgress from '../../components/loaders/LinearProgress';

import ClientContext from './components/ClientContext';
import MainContainer from './components/MainContainer';
import BuildingsTable from './components/BuildingsTable';

const Widgets = ({ statistics }) => {
    return (
        <Grid container spacing={{ xs: 2, lg: 3.5 }}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Widget title="Active campaigns" statistic={statistics.activeCampaignsCount} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Widget
                    title="Active Campaign Revenue"
                    statistic={formatAmountInCentsRound(statistics.sumRevenue)}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Widget
                    title="Total Revenue"
                    statistic={formatAmountInCentsRound(statistics.sumRevenueTotal)}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Widget
                    title="Upgrades sold to date"
                    statistic={formatAmountInCentsRound(statistics.sumPriceTotal)}
                />
            </Grid>
        </Grid>
    );
};

const CampaignsPage = () => {
    const company = useContext(ClientContext);
    const { companyId } = company;
    const { data: statistics } = useGetCompanyStatisticsQuery({ companyId });
    const { data: buildings } = useGetCompanyBuildingsQuery({ companyId });
    if (!statistics || !buildings) {
        return <LinearProgress />;
    }

    return (
        <MainContainer>
            <Box sx={{ mt: 4 }}>
                <Widgets statistics={statistics} />
            </Box>
            <Box sx={{ mt: 3 }}>
                <BuildingsTable buildings={buildings} />
            </Box>
        </MainContainer>
    );
};

export default CampaignsPage;
