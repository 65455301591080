import { useEffect, useState } from 'react';

export const useImageReader = (file) => {
    const [image, setImage] = useState(null);
    useEffect(() => {
        const reader = new FileReader();
        reader.onabort = () => console.warn('file reading was aborted');
        reader.onerror = (err) => console.error('file reading has failed', err);
        reader.onload = () => setImage(reader.result);
        reader.readAsDataURL(file);
    }, [file]);
    return [image, setImage];
};

export default useImageReader;
