import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';

import { ReactComponent as ImageIcon } from './icons/Image.svg';
import { ReactComponent as LinkIcon } from './icons/Link.svg';
import { ReactComponent as CurlyBracesIcon } from './icons/CurlyBraces.svg';
import { ReactComponent as BoldIcon } from './icons/Bold.svg';
import { ReactComponent as UnderlineIcon } from './icons/Underline.svg';
import { ReactComponent as ItalicIcon } from './icons/Italic.svg';
import { ReactComponent as StrikethroughIcon } from './icons/Strikethrough.svg';
import { ReactComponent as FormatSizeIcon } from './icons/FormatSize.svg';
import { ReactComponent as AlignCenterIcon } from './icons/AlignCenter.svg';
import { ReactComponent as AlignLeftIcon } from './icons/AlignLeft.svg';
import { ReactComponent as AlignRightIcon } from './icons/AlignRight.svg';
import { ReactComponent as svgFormatIcon } from './icons/svgFormat.svg';

import { normalFontSize, namedFontSizes, shortcodes } from './ContentEditor';

import styles from './CustomToolbar.module.scss';

export const getRangeFormat = (reactQuillRef, range, format) => {
    const editor = reactQuillRef.current.editor;
    return editor.getFormat(range.index, range.length)[format];
};

const toggleTextFormat = (reactQuillRef, format) => {
    const editor = reactQuillRef.current.editor;
    const range = editor.getSelection();
    if (!range || !range.length) {
        return;
    }

    const formatted = editor.getFormat(range.index, range.length)[format];
    editor.formatText(range.index, range.length, format, !formatted);
};

const toggleTextSize = (reactQuillRef, size) => {
    const editor = reactQuillRef.current.editor;
    const range = editor.getSelection();
    if (!range || !range.length) {
        return;
    }

    if (size === normalFontSize.value) {
        editor.formatText(range.index, range.length, 'size', false);
    } else {
        editor.formatText(range.index, range.length, 'size', size);
    }
};

const toggleLinkEditor = (reactQuillRef) => {
    const editor = reactQuillRef.current.editor;
    const range = editor.getSelection();
    if (!range || !range.length) {
        return;
    }

    let link = editor.getFormat(range.index, range.length).link;
    if (link) {
        editor.format('link', false);
    } else {
        // update placeholder
        const tooltip = editor.theme.tooltip;
        const input = tooltip.root.querySelector('input[data-link]');
        input.dataset.link = 'https://decoro.io';

        link = editor.getText(range.index, range.length);
        tooltip.edit('link', link);
    }
};

const toggleImageFileLoad = (reactQuillRef) => {
    const editor = reactQuillRef.current.editor;
    const range = editor.getSelection();
    if (!range) {
        return;
    }

    editor.theme.modules.imageUploader.selectLocalImage();
};

const toggleShortcode = (reactQuillRef, value) => {
    const editor = reactQuillRef.current.editor;
    const range = editor.getSelection();
    if (!range) {
        return;
    }

    editor.insertEmbed(range.index, 'shortcode', value, 'user');

    // add space after to continue writing
    range.index += 1;
    editor.insertText(range.index, ' ');
};

const toggleFormat = (reactQuillRef, format) => {
    const editor = reactQuillRef.current.editor;
    const range = editor.getSelection();
    if (!range || !range.length) {
        return;
    }

    const formatted = editor.getFormat()['align'];
    if (formatted === format || format === 'left') {
        editor.format('align', false);
    } else {
        editor.format('align', format);
    }
};

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
const FontSizeSelect = ({ fontSize, onChange }) => {
    if (isSafari) {
        return (
            <Select native value={fontSize} onChange={onChange}>
                {namedFontSizes.map(({ name, value }) => (
                    <option key={value} value={value}>
                        {name}
                    </option>
                ))}
            </Select>
        );
    }

    return (
        <Select
            value={fontSize}
            onChange={onChange}
            renderValue={() => (
                <div className={styles.selectIcon}>
                    <SvgIcon
                        component={FormatSizeIcon}
                        viewBox="0 0 24 18"
                        sx={{ width: 24, height: 24 }}
                    />
                </div>
            )}
        >
            {namedFontSizes.map(({ name, value }) => (
                <MenuItem key={value} value={value} sx={{ fontSize: value }}>
                    {name}
                </MenuItem>
            ))}
        </Select>
    );
};

const ShortcodeSelect = ({ onChange }) => {
    if (isSafari) {
        return (
            <Select native value="none" onChange={onChange} style={{ width: '60px' }}>
                <option value="none" disabled>
                    {'{}'}
                </option>
                {shortcodes.map(({ name, value }) => (
                    <option key={value} value={value}>
                        {name}
                    </option>
                ))}
            </Select>
        );
    }

    return (
        <Select
            value="none"
            onChange={onChange}
            renderValue={() => (
                <div className={styles.selectIcon}>
                    <SvgIcon
                        component={CurlyBracesIcon}
                        viewBox="0 0 20 16"
                        sx={{ width: 16, height: 16 }}
                    />
                </div>
            )}
        >
            <MenuItem value="none" divider disabled sx={{ display: 'none' }} />
            {shortcodes.map(({ name, value }) => (
                <MenuItem key={value} value={value}>
                    {name}
                </MenuItem>
            ))}
        </Select>
    );
};

export const CustomToolbar = ({ reactQuillRef, fontSize, onSelectFontSize }) => {
    const handleClickTextFormat = (event) => {
        toggleTextFormat(reactQuillRef, event.currentTarget.value);
    };

    const handleChangeSize = (event) => {
        const size = event.target.value;
        toggleTextSize(reactQuillRef, size);
        onSelectFontSize(size);
    };

    const handleClickLink = () => {
        toggleLinkEditor(reactQuillRef);
    };

    const handleClickImage = () => {
        toggleImageFileLoad(reactQuillRef);
    };

    const handleSvgUpload = (reactQuillRef, event) => {
        const file = event.target.files[0];
        if (file && file.type === 'image/svg+xml') {
            const reader = new FileReader();

            reader.onload = () => {
                const editor = reactQuillRef.current.editor;
                const range = editor.getSelection();
                if (range) {
                    editor.insertEmbed(range.index, 'image', reader.result, 'user');
                    editor.setSelection(range.index + 1);
                }
            };

            reader.readAsDataURL(file);
        }
    };

    const handleChangeShortcode = (event) => {
        const shortcode = event.target.value;
        toggleShortcode(reactQuillRef, shortcode);
    };

    const handleClickFormat = (event) => {
        toggleFormat(reactQuillRef, event.currentTarget.value);
    };

    return (
        <Stack direction="row" spacing={0.5} flexWrap="wrap">
            <Divider orientation="vertical" flexItem />
            <FormControl size="small" className={styles.selectControl}>
                <FontSizeSelect fontSize={fontSize} onChange={handleChangeSize} />
            </FormControl>
            <Divider orientation="vertical" flexItem />
            <IconButton sx={{ width: 40, height: 40 }} onClick={handleClickTextFormat} value="bold">
                <SvgIcon component={BoldIcon} viewBox="0 0 12 16" sx={{ width: 16, height: 16 }} />
            </IconButton>
            <IconButton
                sx={{ width: 40, height: 40 }}
                onClick={handleClickTextFormat}
                value="underline"
            >
                <SvgIcon
                    component={UnderlineIcon}
                    viewBox="0 0 12 16"
                    sx={{ width: 16, height: 16 }}
                />
            </IconButton>
            <IconButton
                sx={{ width: 40, height: 40 }}
                onClick={handleClickTextFormat}
                value="italic"
            >
                <SvgIcon
                    component={ItalicIcon}
                    viewBox="0 0 10 12"
                    sx={{ width: 12, height: 12 }}
                />
            </IconButton>
            <IconButton
                sx={{ width: 40, height: 40 }}
                onClick={handleClickTextFormat}
                value="strike"
            >
                <SvgIcon
                    component={StrikethroughIcon}
                    viewBox="0 0 16 14"
                    sx={{ width: 16, height: 16 }}
                />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton sx={{ width: 40, height: 40 }} onClick={handleClickFormat} value="left">
                <SvgIcon
                    component={AlignLeftIcon}
                    viewBox="0 0 18 16"
                    sx={{ width: 16, height: 16 }}
                />
            </IconButton>
            <IconButton sx={{ width: 40, height: 40 }} onClick={handleClickFormat} value="center">
                <SvgIcon
                    component={AlignCenterIcon}
                    viewBox="0 0 18 16"
                    sx={{ width: 16, height: 16 }}
                />
            </IconButton>
            <IconButton sx={{ width: 40, height: 40 }} onClick={handleClickFormat} value="right">
                <SvgIcon
                    component={AlignRightIcon}
                    viewBox="0 0 18 16"
                    sx={{ width: 16, height: 16 }}
                />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <FormControl size="small" className={styles.selectControl}>
                <ShortcodeSelect onChange={handleChangeShortcode} />
            </FormControl>
            <Divider orientation="vertical" flexItem />
            <IconButton sx={{ width: 40, height: 40 }} onClick={handleClickLink}>
                <SvgIcon component={LinkIcon} viewBox="0 0 19 19" sx={{ width: 16, height: 16 }} />
            </IconButton>
            <IconButton sx={{ width: 40, height: 40 }} onClick={handleClickImage}>
                <SvgIcon component={ImageIcon} viewBox="0 0 18 18" sx={{ width: 16, height: 16 }} />
            </IconButton>
            <Divider orientation="vertical" flexItem />
            <IconButton sx={{ width: 40, height: 40 }}>
                <input
                    accept=".svg"
                    type="file"
                    style={{ display: 'none' }}
                    id="upload-svg-icon"
                    onChange={(event) => handleSvgUpload(reactQuillRef, event)}
                />
                <label htmlFor="upload-svg-icon">
                    <SvgIcon
                        component={svgFormatIcon}
                        viewBox="0 0 24 24"
                        sx={{ width: 24, height: 24, path: { fill: 'none' }, marginTop: 0.7 }}
                    />
                </label>
            </IconButton>
        </Stack>
    );
};

export default CustomToolbar;
