import styled from 'styled-components';
import Container from '@mui/material/Container';

const MainContainer = ({ sx = { pb: 5 }, children }) => {
    return (
        <StyledContainer sx={sx} maxWidth={false}>
            {children}
        </StyledContainer>
    );
};

const StyledContainer = styled(Container)`
    padding-left: 80px;
    padding-right: 80px;
`;

export default MainContainer;
