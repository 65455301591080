import React, { useContext } from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';

import {
    useGetCompanyStatisticsQuery,
    useGetCompanySubscriptionQuery,
} from '../../redux/companies/companies.api';

import { formatAmountInCentsRound } from '../../utils/prices';
import Widget from '../../components/Widget/Widget';
import LinearProgress from '../../components/loaders/LinearProgress';

import CompanyInfo from './components/CompanyInfo';
import CompanyPricing from './components/CompanyPricing';
import PandaDocAccount from './components/PandaDocAccount';
import CompanyNotes from './components/CompanyNotes';
import ClientContext from './components/ClientContext';
import MainContainer from './components/MainContainer';

const Widgets = ({ statistics }) => {
    return (
        <Grid container spacing={{ xs: 2, lg: 3.5 }}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Widget title="Active campaigns" statistic={statistics.activeCampaignsCount} />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Widget
                    title="Active Campaign Revenue"
                    statistic={formatAmountInCentsRound(statistics.sumRevenue)}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Widget
                    title="Total Revenue"
                    statistic={formatAmountInCentsRound(statistics.sumRevenueTotal)}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Widget
                    title="Upgrades sold to date"
                    statistic={formatAmountInCentsRound(statistics.sumPriceTotal)}
                />
            </Grid>
        </Grid>
    );
};

const DashboardPage = () => {
    const company = useContext(ClientContext);
    const { companyId } = company;
    const { data: statistics } = useGetCompanyStatisticsQuery({ companyId });
    const { data: subscription } = useGetCompanySubscriptionQuery({ companyId });
    if (!statistics) {
        return <LinearProgress />;
    }

    return (
        <MainContainer>
            <Box sx={{ mt: 4 }}>
                <Widgets statistics={statistics} />
            </Box>
            <Box sx={{ mt: 4 }}>
                <Grid container spacing={{ xs: 2, lg: 5 }} direction="row">
                    <Grid item lg={6} xs={12}>
                        <CompanyInfo company={company} />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <CompanyPricing company={company} subscription={subscription} />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <PandaDocAccount company={company} />
                    </Grid>
                    <Grid item lg={6} xs={12}>
                        <CompanyNotes company={company} />
                    </Grid>
                </Grid>
            </Box>
        </MainContainer>
    );
};

export default DashboardPage;
