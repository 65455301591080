import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';

import { toRelative } from '../../utils/date';
import useDialog from '../../hooks/useDialog';
import useFilter from '../../hooks/useFilter';
import {
    useCreateCompanyMutation,
    useLazyFindCompaniesQuery,
    useUpdateCompanyMutation,
} from '../../redux/companies/companies.api';

import Chip from '../../components/Chip/Chip';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import MainContainer from '../../components/layouts/MainContainer/MainContainer';
import Pagination from '../../components/Pagination/Pagination';
import LinearProgress from '../../components/loaders/LinearProgress';
import Search from '../../components/Search/Search';

import ClientProfilePopover from '../Client/components/ClientProfilePopover';
import ClientFormModal from '../Client/components/ClientFormModal';

import { ReactComponent as ExclamationCircleIcon } from '../../assets/svg/ExclamationCircle.svg';

const statusColors = {
    ACTIVE: 'success',
    INACTIVE: 'secondary',
    PENDING: 'warning',
    SUSPENDED: 'error',
    DRAFT: 'secondary',
    BLOCKED: 'default',
};

const getLastActivity = (date) => toRelative(date) || 'Never';
const tooltipProps = {
    tooltip: {
        sx: {
            backgroundColor: '#3B4857',
            '& .MuiTooltip-arrow': {
                color: '#3B4857',
            },
        },
    },
};

const PaymentInfo = ({ company }) => {
    const expires = useMemo(() => {
        if (!company.stripeCustomer) {
            return { color: 'secondary', title: 'No subscription' };
        }

        const { cardExpMonth, cardExpYear, subscriptionId, status } = company.stripeCustomer;
        if (!subscriptionId) {
            return { color: 'secondary', title: 'No subscription' };
        }

        if (status && status !== 'active') {
            return { color: 'error', title: 'Subscription expired' };
        }

        if (!cardExpMonth || !cardExpYear) {
            return null;
        }

        const cardExpDate = new Date(cardExpYear, cardExpMonth - 1);
        const dateTime = DateTime.fromJSDate(cardExpDate);
        const startOfMonth = DateTime.now().startOf('month');
        const months = dateTime.diff(startOfMonth, 'month').as('month');
        if (months < 0) {
            return {
                color: 'error',
                title: `Payment card expired ${dateTime.toFormat('MM/yyyy')}`,
            };
        }

        if (months === 0) {
            return {
                color: 'warning',
                title: `Payment card expires ${dateTime.toFormat('MM/yyyy')}`,
            };
        }

        return null;
    }, [company.stripeCustomer]);

    if (expires) {
        return (
            <Tooltip arrow title={expires.title} componentsProps={tooltipProps} placement="top">
                <StyledSvgIcon
                    color={expires.color}
                    component={ExclamationCircleIcon}
                    viewBox="0 0 20 19"
                    sx={{ width: 20, height: 20, verticalAlign: 'middle' }}
                />
            </Tooltip>
        );
    }

    return null;
};

const CompanyRow = ({ company }) => {
    const [updateCompany, { isLoading: isLoadingUpdate, isSuccess: isSuccessUpdate }] =
        useUpdateCompanyMutation();

    const preventClickEvent = (event) => event.stopPropagation();
    const history = useHistory();
    const handleClickRow = () => {
        history.push(`/clients/${company.companyId}/dashboard`);
    };

    const handleSubmit = (values) => {
        updateCompany({ companyId: company.companyId, ...values });
    };

    const editDialog = useDialog();
    useEffect(() => {
        if (isSuccessUpdate) {
            editDialog.handleClose();
        }
    }, [isSuccessUpdate]);

    return (
        <TableRow onClick={handleClickRow} sx={{ cursor: 'pointer' }}>
            <TableCell>
                {company.companyName} <PaymentInfo company={company} />
            </TableCell>
            <TableCell>{company.statisticActiveCampaign?.value}</TableCell>
            <TableCell>
                {company.status && (
                    <Chip
                        sx={{ ml: 0 }}
                        label={company.status}
                        color={statusColors[company.status]}
                    />
                )}
            </TableCell>
            <TableCell>{getLastActivity(company.lastUser?.lastLogin)}</TableCell>
            <TableCell onClick={preventClickEvent} sx={{ width: 38 }}>
                <ClientProfilePopover
                    userId={company.admin?.userId}
                    companyId={company.companyId}
                    clientInfo={company}
                    onEditClick={editDialog.handleOpen}
                />
                {editDialog.open && (
                    <ClientFormModal
                        open={editDialog.open}
                        client={company}
                        onClose={editDialog.handleClose}
                        onSubmit={handleSubmit}
                        isLoading={isLoadingUpdate}
                    />
                )}
            </TableCell>
        </TableRow>
    );
};

const CompaniesTable = ({ companies, pagination, onChangePagination }) => {
    if (!companies) {
        return null;
    }

    if (companies.length === 0) {
        return 'Clients not found';
    }

    return (
        <TableContainer sx={{ mt: 3 }}>
            <Table size="small" sx={{ borderCollapse: 'separate', borderSpacing: '0 16px' }}>
                <TableHead>
                    <TableRow>
                        <TableCell>Client</TableCell>
                        <TableCell>Active campaigns</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Last activity</TableCell>
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {companies.map((company) => (
                        <CompanyRow key={company.companyId} company={company} />
                    ))}
                </TableBody>
            </Table>
            <Grid container justifyContent="center" sx={{ mt: 4 }}>
                <Grid item>
                    <Pagination
                        total={pagination.total}
                        limit={pagination.limit}
                        offset={pagination.offset}
                        onChange={onChangePagination}
                    />
                </Grid>
            </Grid>
        </TableContainer>
    );
};

const getPaginatedCompanies = (data = { count: 0 }) => ({
    companies: data.rows,
    count: data.count,
});

const ClientsPage = () => {
    const [findCompanies, { data, isFetching }] = useLazyFindCompaniesQuery();
    const [
        createCompany,
        { error: errorCreate, isLoading: isLoadingCreate, isSuccess: isSuccessCreate },
    ] = useCreateCompanyMutation();

    const { companies, count } = getPaginatedCompanies(data);
    const { search, limit, offset, pagination, handleSearch, handlePagination } = useFilter({
        count,
        limit: 15,
        offset: 0,
        search: '',
    });

    useEffect(() => {
        findCompanies({ search, limit, offset }, { preferCacheValue: true });
    }, [search, limit, offset]);

    const addDialog = useDialog();
    const handleSubmitCreate = (values) => {
        createCompany(values);
    };

    useEffect(() => {
        if (isSuccessCreate) {
            addDialog.handleClose();
        }
    }, [isSuccessCreate]);

    return (
        <MainContainer>
            {isFetching && <LinearProgress />}
            <Grid container spacing={3} direction="row" alignItems="center">
                <Grid item>
                    <Typography variant="h3">Clients</Typography>
                </Grid>
                <Grid item xs>
                    <Search search={search} onSearch={handleSearch} />
                </Grid>
                <Grid item>
                    <PrimaryButton sx={{ py: 1, px: 5 }} onClick={addDialog.handleOpen}>
                        Add client
                    </PrimaryButton>
                    {addDialog.open && (
                        <ClientFormModal
                            title="Add Client"
                            submitLabel="Add"
                            open={addDialog.open}
                            errorResponse={errorCreate}
                            isLoading={isLoadingCreate}
                            onClose={addDialog.handleClose}
                            onSubmit={handleSubmitCreate}
                        />
                    )}
                </Grid>
            </Grid>
            <CompaniesTable
                companies={companies}
                pagination={pagination}
                onChangePagination={handlePagination}
            />
        </MainContainer>
    );
};

const StyledSvgIcon = styled(SvgIcon)`
    &.MuiSvgIcon-colorSecondary {
        path {
            fill: #888888;
        }
    }

    &.MuiSvgIcon-colorWarning {
        path {
            fill: #f89820;
        }
    }

    &.MuiSvgIcon-colorError {
        path {
            fill: #db354a;
        }
    }
`;

export default ClientsPage;
