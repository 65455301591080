import { axiosInstance } from './axiosInstance';

export const axiosBaseQuery =
    () =>
    async ({ url, method, data, params, responseType }) => {
        try {
            const result = await axiosInstance({ url, method, data, params, responseType });
            return { data: result.data };
        } catch (axiosError) {
            let err = axiosError;
            return {
                error: { status: err.response?.status, data: err.response?.data },
            };
        }
    };
