import React, { useEffect, useMemo } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import { baseHostname } from '../../../utils/domains';
import FormModal from '../../../components/FormModal/FormModal';

const clientNameRE = /^[\w\d]+.*[\w\d]+$/gi;
const clientSubdomainRE = /^[a-z\d]+$/g;

const validationSchema = yup.object().shape({
    companyName: yup.string().matches(clientNameRE).max(60).required(),
    subdomainName: yup.string().matches(clientSubdomainRE).min(2).max(15).required(),
    address: yup.string().max(100).nullable(),
    city: yup.string().max(100).nullable(),
    province: yup.string().max(100).nullable(),
    postalCode: yup.string().max(15).nullable(),
    country: yup.string().max(100).nullable(),
    phone: yup.string().min(5).max(20).nullable(),
    email: yup.string().email().nullable(),
    adminEmail: yup.string().email().required(),
});

const getClientValues = (company = {}) => ({
    subdomainName: company.subdomain?.name || '',
    companyName: company.companyName || '',
    address: company.address || '',
    city: company.city || '',
    province: company.province || '',
    postalCode: company.postalCode || '',
    country: company.country || '',
    phone: company.phone || '',
    email: company.email || '',
    adminEmail: company.admin?.email || '',
});

export const getClientData = (values = {}, isRegistered) => ({
    subdomainName: values.subdomainName,
    companyName: values.companyName,
    address: values.address,
    city: values.city,
    province: values.province,
    postalCode: values.postalCode,
    country: values.country,
    phone: values.phone || null,
    email: values.email || null,
    ...(!isRegistered && { adminEmail: values.adminEmail }),
});

const getErrorMessage = (error = { data: {} }) => error.data?.error || error.message || '';
const getErrors = (res) => {
    if (!res) {
        return {};
    }

    const message = getErrorMessage(res);
    const lowerCaseMessage = message.toLowerCase();
    if (lowerCaseMessage.includes('company name')) {
        return { companyName: message };
    }

    if (lowerCaseMessage.includes('subdomain')) {
        return { subdomainName: message };
    }

    if (lowerCaseMessage.includes('email')) {
        return { email: message };
    }

    return {};
};

export const ClientFormModal = ({
    client,
    open,
    onClose,
    onSubmit,
    isLoading,
    errorResponse,
    submitLabel = 'Edit',
    title = 'Edit Client',
}) => {
    const isRegistered = useMemo(
        () => client && !['DRAFT', 'PENDING'].includes(client.status),
        [client]
    );

    const formik = useFormik({
        validationSchema,
        initialValues: getClientValues(client),
        onSubmit: (values) => onSubmit(getClientData(values, isRegistered)),
    });

    useEffect(() => {
        if (!errorResponse) {
            return;
        }

        const errors = getErrors(errorResponse);
        // the errors are not shown on untouched form
        formik.setErrors(errors);
    }, [errorResponse]);

    return (
        <FormModal
            title={title}
            submitLabel={submitLabel}
            cancelLabel="Cancel"
            open={open}
            onClose={onClose}
            isLoading={isLoading}
            onSubmit={formik.handleSubmit}
            minWidth={440}
        >
            <Grid container spacing={4} maxWidth={440}>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        name="companyName"
                        error={formik.errors.companyName && formik.touched.companyName}
                        helperText={formik.touched.companyName && formik.errors.companyName}
                        onChange={formik.handleChange}
                        value={formik.values.companyName}
                        InputLabelProps={{ shrink: true }}
                        label="Company Name"
                        size="small"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        name="subdomainName"
                        error={formik.errors.subdomainName && formik.touched.subdomainName}
                        helperText={formik.touched.subdomainName && formik.errors.subdomainName}
                        onChange={formik.handleChange}
                        value={formik.values.subdomainName}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">.{baseHostname}</InputAdornment>
                            ),
                        }}
                        InputLabelProps={{ shrink: true }}
                        label="Subdomain"
                        size="small"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name="address"
                        error={formik.errors.address && formik.touched.address}
                        helperText={formik.touched.address && formik.errors.address}
                        onChange={formik.handleChange}
                        value={formik.values.address}
                        InputLabelProps={{ shrink: true }}
                        label="Address"
                        size="small"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name="city"
                        error={formik.errors.city && formik.touched.city}
                        helperText={formik.touched.city && formik.errors.city}
                        onChange={formik.handleChange}
                        value={formik.values.city}
                        InputLabelProps={{ shrink: true }}
                        label="City"
                        size="small"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        name="province"
                        error={formik.errors.province && formik.touched.province}
                        helperText={formik.touched.province && formik.errors.province}
                        onChange={formik.handleChange}
                        value={formik.values.province}
                        InputLabelProps={{ shrink: true }}
                        label="Province"
                        size="small"
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        name="postalCode"
                        error={formik.errors.postalCode && formik.touched.postalCode}
                        helperText={formik.touched.postalCode && formik.errors.postalCode}
                        onChange={formik.handleChange}
                        value={formik.values.postalCode}
                        InputLabelProps={{ shrink: true }}
                        label="Postal Code"
                        size="small"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name="country"
                        error={formik.errors.country && formik.touched.country}
                        helperText={formik.touched.country && formik.errors.country}
                        onChange={formik.handleChange}
                        value={formik.values.country}
                        InputLabelProps={{ shrink: true }}
                        label="Country"
                        size="small"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        name="phone"
                        error={formik.errors.phone && formik.touched.phone}
                        helperText={formik.touched.phone && formik.errors.phone}
                        onChange={formik.handleChange}
                        value={formik.values.phone}
                        InputLabelProps={{ shrink: true }}
                        label="Phone"
                        size="small"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        fullWidth
                        disabled={isRegistered}
                        type="email"
                        name="adminEmail"
                        error={formik.errors.adminEmail && formik.touched.adminEmail}
                        helperText={formik.touched.adminEmail && formik.errors.adminEmail}
                        onChange={formik.handleChange}
                        value={formik.values.adminEmail}
                        InputLabelProps={{ shrink: true }}
                        label="Company Owner Email"
                        size="small"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        type="email"
                        name="email"
                        error={formik.errors.email && formik.touched.email}
                        helperText={formik.touched.email && formik.errors.email}
                        onChange={formik.handleChange}
                        value={formik.values.email}
                        InputLabelProps={{ shrink: true }}
                        label="Company Email"
                        size="small"
                    />
                </Grid>
            </Grid>
        </FormModal>
    );
};

export default ClientFormModal;
