export const toPlural = (value, single, plural) => {
    if (Array.isArray(value)) {
        return toPlural(value.length, single, plural);
    }

    if (Number(value) === 1) {
        return single;
    }

    if (!plural) {
        return `${single}s`;
    }

    return plural;
};

export const pluralize = (value, single, plural) => {
    if (Array.isArray(value)) {
        return `${value.length} ${toPlural(value.length, single, plural)}`;
    }

    return `${value} ${toPlural(value, single, plural)}`;
};
