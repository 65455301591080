import React, { useMemo } from 'react';
import Zoom from 'react-medium-image-zoom';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import SvgIcon from '@mui/material/SvgIcon';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

import { useDeleteImageMutation } from '../../../redux/rooms/rooms.api';

import { ReactComponent as DeleteOutlineIcon } from '../../../assets/svg/DeleteOutline.svg';

const RoomImageCard = ({ image, height }) => {
    const [deleteImage, { isLoading }] = useDeleteImageMutation();
    const handleDelete = () => {
        deleteImage({ roomId: image.roomId, imageId: image.imageId });
    };

    const styles = useMemo(() => {
        if (height > 60) {
            return { button: { bottom: 12, right: 12 }, icon: { width: 14, height: 14 } };
        }

        return { button: { p: 0.5, bottom: 6, right: 6 }, icon: { width: 8, height: 8 } };
    }, [height]);

    return (
        <Card
            elevation={0}
            sx={{
                position: 'relative',
                borderRadius: 0,
            }}
        >
            <Zoom>
                <CardMedia
                    component="img"
                    height={height}
                    image={image.location}
                    alt={`img-${image.imageId}`}
                />
            </Zoom>
            <StyledIconButton disabled={isLoading} onClick={handleDelete} sx={styles.button}>
                <SvgIcon
                    inheritViewBox
                    className="svg-icon"
                    component={DeleteOutlineIcon}
                    sx={styles.icon}
                />
            </StyledIconButton>
        </Card>
    );
};

const StyledIconButton = styled(IconButton)`
    position: absolute;
    border: 0.5px solid var(--company-primary-color);
    background-color: #ffffff !important;
`;

export default RoomImageCard;
