import { bindActionCreators } from '@reduxjs/toolkit';

import AuthService from '../services/AuthService';
import { resetAuth } from '../redux/common/commonSlice';

const identity = (id) => id;
const getErrorData = (err) => err && err.response && err.response.data;
const isAuthError = (data) => data && (
    data.status === 401 ||
    (data.status === 500 && data.error === 'invalid token') ||
    (data.status === 500 && data.error === 'jwt expired') ||
    (data.status === 403 && data.error === 'permission is not allowed')
);

export const useAuth = (axios, store) => {
    const actions = bindActionCreators({ resetAuth }, store.dispatch);
    axios.interceptors.request.use(async (config) => {
        if (AuthService.isAuthenticated()) {
            config.headers.accessToken = AuthService.getToken();
        }

        return config;
    });

    axios.interceptors.response.use(identity, (error) => {
        const data = getErrorData(error);
        if (isAuthError(data)) {
            actions.resetAuth();
            AuthService.removeToken();
        }

        throw error;
    });
};
